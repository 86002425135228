import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./buttonmodal.css";
import Form from "react-bootstrap/Form";
import blank2 from "../../assets/images/blank2.png";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import { useAppContext } from "../../context/appContext";

const ButtonModal = ({ labels, style, handler, closemodal }) => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  const { logoutUser, mProfilePicture } = useAppContext();
  const [show, setShow] = useState(false);
  const [mfirstName, setMfirstName] = useState(null);
  const [preferredName, setPreferredName] = useState(null);
  const [mlastName, setMlastName] = useState(null);
  const [mgender, setMGender] = useState(null);
  const [mstatus, setMstatus] = useState(null);
  const [mmarital, setMarital] = useState(null);
  const [mfamily, setMfamily] = useState(null);
  const [mhomePhone, setMhomePhone] = useState(null);
  const [mworkPhone, setMworkPhone] = useState(null);
  const [maddress, setMaddress] = useState(null);
  const [memail, setmemail] = useState(null);
  const [mpostal, setMpostal] = useState(null);
  const [mcity, setMCity] = useState(null);
  const [mschool, setMschool] = useState(null);
  const [mgrade, setMgrade] = useState(null);
  const [memployer, setEmployer] = useState(null);
  const [mBirthday, setMBirthday] = useState(null);
  const [mBirthMonth, setMBirthMonth] = useState(null);
  const [mAnniversaryDay, setMAnniversaryDay] = useState(null);
  const [mAnniversaryMonth, setMAnniversaryMonth] = useState(null);
  const [mmobilePhone, setMmobilePhone] = useState(null);
  const [facebook, setFacebook] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [instagram, setInstagram] = useState(null);
  const [linkedin, setLinkedin] = useState(null);
  const [notes, setnotes] = useState(null);
  const [state, setstate] = useState(null);
  const [image, setImage] = useState(mProfilePicture);
  const [preview, setPreview] = useState(blank2); // Replace with your placeholder image URL

  const handleClose = () => setShow(false);
  const showModal = (breakpoint) => {
    setShow(true);
  };
  const [mydata, setMydata] = useState([]);

  const createPersons = (e) => {
    e.preventDefault();

    if (!mlastName) {
      window.alert("Please enter Family Last Name");
      return;
    }

    const currentMember = {
      mfirstName: mfirstName,
      preferredName: preferredName,
      notes: notes,
      mlastName: mlastName,
      memail: memail,
      mgender: mgender,
      mstatus: mstatus,
      mmarital: mmarital,
      mProfilePicture: image,
      mschool: mschool,
      mgrade: mgrade,
      mfamily: mfamily,
      mhomePhone: mhomePhone,
      mworkPhone: mworkPhone,
      memployer: memployer,
      mmobilePhone: mmobilePhone,
      mcity: mcity,
      maddress: maddress,
      mpostal: mpostal,
      familyRole: mfamily,
      mBirthday: mBirthday,
      mBirthMonth: mBirthMonth,
      mAnniversaryDay: mAnniversaryDay,
      mAnniversaryMonth: mAnniversaryMonth,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      linkedin: linkedin,
      mstate: state,
      tempId: Math.floor(Math.random() * 1000),
    };
    console.log(currentMember);
    setMydata([...mydata, currentMember]);
    handler([...mydata, currentMember]);
    handleClose();
    closemodal();
  };

  const [genderx, setGenderx] = useState();
  const showGender = () => {
    setGenderx(!genderx);
  };

  const [anniversaryx, setAnniversaryx] = useState();
  const showAnniversary = () => {
    setAnniversaryx(!anniversaryx);
  };

  // const [notesx, setNotesx] = useState();
  // const showNotes = () => {
  //   setNotesx(!notesx);
  // };

  const [facebookx, setFacebookx] = useState();
  const showFacebook = () => {
    setFacebookx(!facebookx);
  };

  const [twitterx, setTwitterx] = useState();
  const showTwitter = () => {
    setTwitterx(!twitterx);
  };

  const [instagramx, setInstagramx] = useState();
  const showInstagram = () => {
    setInstagramx(!instagramx);
  };

  const [linkedinx, setLinkedinx] = useState();
  const showLinkedin = () => {
    setLinkedinx(!linkedinx);
  };

  const [notex, setNotex] = useState();
  const showNotes = () => {
    setNotex(!notex);
  };
  const [statusx, setStatusx] = useState();
  const showStatus = () => {
    setStatusx(!statusx);
  };
  const [maritalx, setMaritalx] = useState();
  const showMartial = () => {
    setMaritalx(!maritalx);
  };

  const [agex, setAgex] = useState();
  const showAge = () => {
    setAgex(!agex);
  };

  const [familyx, setFamilyx] = useState(true);
  const showFamily = () => {
    setFamilyx(!familyx);
  };

  const [gradex, setGradex] = useState();
  const showGrade = () => {
    setGradex(!gradex);
  };
  const [schoolx, setSchoolx] = useState();
  const showSchool = () => {
    setSchoolx(!schoolx);
  };

  const [employerx, setEmployerx] = useState();
  const showEmployer = () => {
    setEmployerx(!employerx);
  };

  const [mobilephonex, setmobilePhonex] = useState();
  const showmobilePhone = () => {
    setmobilePhonex(!mobilephonex);
  };

  const [homephonex, sethomephonex] = useState();
  const showhomephone = () => {
    sethomephonex(!homephonex);
  };

  const [workphonex, setworkPhonex] = useState();
  const showworkPhone = () => {
    setworkPhonex(!workphonex);
  };

  const [emailx, setemailx] = useState();
  const showemailx = () => {
    setemailx(!emailx);
  };

  const [addressx, setaddressx] = useState();
  const showaddressx = () => {
    setaddressx(!addressx);
  };

  const [cityx, setcityx] = useState();
  const showcity = () => {
    setcityx(!cityx);
  };

  const [postalcodex, setpostalcodex] = useState();
  const showpostalcodex = () => {
    setpostalcodex(!postalcodex);
  };

  const [statex, setstatex] = useState();
  const showstate = () => {
    setstatex(!statex);
  };

  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");

  const handleImagez = (e) => {
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));
    setUploadButtonText(file.name);

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        // Maximum dimensions
        const maxWidth = 100;
        const maxHeight = 120;
        let newWidth = img.width;
        let newHeight = img.height;

        // Calculate the width and height, maintaining aspect ratio
        if (newWidth > maxWidth) {
          newHeight = (maxWidth / newWidth) * newHeight;
          newWidth = maxWidth;
        }
        if (newHeight > maxHeight) {
          newWidth = (maxHeight / newHeight) * newWidth;
          newHeight = maxHeight;
        }

        // Resize the image
        Resizer.imageFileResizer(
          file,
          newWidth,
          newHeight,
          "JPEG",
          100,
          0,
          async (uri) => {
            try {
              let { data } = await authFetch.post("/members/upload-image", {
                image: uri,
              });

              setImage(data);
            } catch (err) {
              console.error(err);
            }
          }
        );
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <button className={style} onClick={showModal}>
        {labels}
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Main</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container fluid>
            <Row>
              <Col xs={12} md={6} lg={4} xl={4}>
                <p className="padt2">General Information</p>
                <div>
                  <Form.Check
                    label="Birth Date"
                    aria-label="option 3"
                    onClick={showAge}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Anniversary Date"
                    aria-label="option 3"
                    onClick={showAnniversary}
                    className="wedwe"
                  />

                  <Form.Check
                    inline
                    label="Marital Status"
                    aria-label="option 4"
                    onClick={showMartial}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Note"
                    aria-label="option 2"
                    onClick={showNotes}
                    className="wedwe"
                  />
                </div>

                <div className="padt">
                  <p className="padt2">Contact</p>
                  <Form.Check
                    label="Email Address"
                    aria-label="option 5"
                    onClick={showemailx}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Address"
                    aria-label="option 6"
                    onClick={showaddressx}
                    className="wedwe"
                  />
                  <Form.Check
                    label="City"
                    aria-label="option 7"
                    onClick={showcity}
                    className="wedwe"
                  />
                  <Form.Check
                    label="State"
                    aria-label="option 7"
                    onClick={showstate}
                    className="wedwe"
                  />

                  <Form.Check
                    label="Postal Code"
                    aria-label="option 8"
                    onClick={showpostalcodex}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Mobile Phone"
                    aria-label="option 9"
                    onClick={showmobilePhone}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Work Phone"
                    aria-label="option 10"
                    onClick={showworkPhone}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Home Phone"
                    aria-label="option 12"
                    onClick={showhomephone}
                    className="wedwe"
                  />
                </div>

                <div className="padt">
                  <p className="padt2">Social Media</p>

                  <Form.Check
                    label="Facebook"
                    aria-label="option 9"
                    onClick={showFacebook}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Twitter"
                    aria-label="option 10"
                    className="wedwe"
                    onClick={showTwitter}
                  />
                  <Form.Check
                    label="Instagram"
                    aria-label="option 11"
                    className="wedwe"
                    onClick={showInstagram}
                  />
                  <Form.Check
                    label="LinkedIn"
                    aria-label="option 12"
                    className="wedwe"
                    onClick={showLinkedin}
                  />
                </div>
                <div className="padt">
                  <p className="padt2">Education & Work</p>
                  <Form.Check
                    label="School"
                    aria-label="option 6"
                    onClick={showSchool}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Grade"
                    aria-label="option 7"
                    onClick={showGrade}
                    className="wedwe"
                  />
                  <Form.Check
                    label="Employer"
                    aria-label="option 8"
                    onClick={showEmployer}
                    className="wedwe"
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={8} xl={8}>
                <p className="formtext mt-5">General Information</p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div style={{ paddingBottom: 20 }}>
                    {preview && (
                      <img
                        src={preview}
                        alt="Uploaded image"
                        width="100"
                        height="120"
                      />
                    )}
                  </div>
                  {/* <Form.Label className="hed btn addfambut2 btn-block">
                    Upload Picture
                  </Form.Label> */}
                  {/* <button type="submit" className="btn addfambut2 btn-block">
                    Upload Picture
                  </button> */}

                  <Form.Control
                    type="file"
                    name="image"
                    value={mProfilePicture}
                    onChange={(e) => handleImagez(e)}
                    accept="image/*"
                    className="formvalue"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="hed">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={mfirstName}
                    name="mfirstName"
                    onChange={(e) => setMfirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="hed">Preferred Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={preferredName}
                    name="preferredName"
                    onChange={(e) => setPreferredName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="hed">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={mlastName}
                    name="mlastName"
                    onChange={(e) => setMlastName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <p className="hed">Gender</p>
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    aria-label="radio 1"
                    value="Male"
                    onChange={(e) => setMGender(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    aria-label="radio 1"
                    value="Female"
                    onChange={(e) => setMGender(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <p className="hed">Status</p>
                  <Form.Check
                    type="radio"
                    label="Member"
                    name="mstatus"
                    aria-label="radio 1"
                    value="Member"
                    onChange={(e) => setMstatus(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label="Attender"
                    name="mstatus"
                    aria-label="radio 1"
                    value="Attender"
                    onChange={(e) => setMstatus(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label="Visitor"
                    name="mstatus"
                    aria-label="radio 1"
                    value="Visitor"
                    onChange={(e) => setMstatus(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label="No Longer Attends"
                    name="mstatus"
                    aria-label="radio 1"
                    value="No Longer Attends"
                    onChange={(e) => setMstatus(e.target.value)}
                  />
                </Form.Group>
                {agex && (
                  <div>
                    <Form.Label className="hed">Birth Date</Form.Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="number"
                            min="1"
                            max="31"
                            value={mBirthday}
                            className="formvalue"
                            //handleChange={handleMemberInput}
                            onChange={(e) => setMBirthday(e.target.value)}
                            name="mBirthday"
                            placeholder="Day"
                            style={{ width: 100 }}
                          />
                        </Form.Group>
                      </div>
                      <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Select
                            value={mBirthMonth}
                            //handleChange={handleMemberInput}
                            onChange={(e) => setMBirthMonth(e.target.value)}
                            name="mBirthMonth"
                            aria-label="Default select example"
                            className="formvalue"
                            style={{ width: 150 }}
                          >
                            <option>Month</option>

                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                )}
                {anniversaryx && (
                  <div>
                    <Form.Label className="hed">Wedding Anniversary</Form.Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="number"
                            min="1"
                            max="31"
                            value={mAnniversaryDay}
                            className="formvalue"
                            //handleChange={handleMemberInput}
                            onChange={(e) => setMAnniversaryDay(e.target.value)}
                            name="mAnniversaryDay"
                            placeholder="Day"
                            style={{ width: 100 }}
                          />
                        </Form.Group>
                      </div>
                      <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Select
                            value={mAnniversaryMonth}
                            // handleChange={handleMemberInput}
                            onChange={(e) =>
                              setMAnniversaryMonth(e.target.value)
                            }
                            name="mAnniversaryMonth"
                            aria-label="Default select example"
                            className="formvalue"
                            style={{ width: 150 }}
                          >
                            <option>Month</option>

                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                )}
                {maritalx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <p className="hed">Marital Status</p>
                    <Form.Check
                      type="radio"
                      label="Single"
                      name="mmarital"
                      aria-label="radio 1"
                      value="single"
                      onChange={(e) => setMarital(e.target.value)}
                    />
                    <Form.Check
                      type="radio"
                      label="Engaged"
                      name="mmarital"
                      aria-label="radio 1"
                      value="engaged"
                      onChange={(e) => setMarital(e.target.value)}
                    />
                    <Form.Check
                      type="radio"
                      label="Married"
                      name="mmarital"
                      aria-label="radio 1"
                      value="married"
                      onChange={(e) => setMarital(e.target.value)}
                    />
                    <Form.Check
                      type="radio"
                      label="Divorced"
                      name="mmarital"
                      aria-label="radio 1"
                      value="divorced"
                      onChange={(e) => setMarital(e.target.value)}
                    />
                    <Form.Check
                      type="radio"
                      label="Widowed"
                      name="mmarital"
                      aria-label="radio 1"
                      value="widowed"
                      onChange={(e) => setMarital(e.target.value)}
                    />
                    <Form.Check
                      type="radio"
                      label="Separated"
                      name="mmarital"
                      aria-label="radio 1"
                      value="Separated"
                      onChange={(e) => setMarital(e.target.value)}
                    />
                    <Form.Check
                      type="radio"
                      label="Other"
                      name="mmarital"
                      aria-label="radio 1"
                      value="Other"
                      onChange={(e) => setMarital(e.target.value)}
                    />
                  </Form.Group>
                )}
                {notex && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="hed">Addition Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={notes}
                      name="notes"
                      onChange={(e) => setnotes(e.target.value)}
                    />
                  </Form.Group>
                )}
                {homephonex ||
                workphonex ||
                mobilephonex ||
                statex ||
                postalcodex ||
                cityx ||
                addressx ||
                emailx ? (
                  <p className="formtext mt-5">Contact Information</p>
                ) : null}
                {homephonex && (
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="hed">Home Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Home Phone"
                      value={mhomePhone}
                      onChange={(e) => setMhomePhone(e.target.value)}
                      name="mhomePhone"
                    />
                  </Form.Group>
                )}
                {emailx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email Address"
                      value={memail}
                      onChange={(e) => setmemail(e.target.value)}
                      name="memail"
                    />
                  </Form.Group>
                )}
                {addressx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address"
                      value={maddress}
                      onChange={(e) => setMaddress(e.target.value)}
                      name="maddress"
                    />
                  </Form.Group>
                )}
                {cityx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter City"
                      value={mcity}
                      className="formvalue"
                      onChange={(e) => setMCity(e.target.value)}
                      name="mcity"
                    />
                  </Form.Group>
                )}
                {statex && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter State"
                      value={state}
                      className="formvalue"
                      onChange={(e) => setstate(e.target.value)}
                      name="state"
                    />
                  </Form.Group>
                )}
                {postalcodex && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Postal Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Postal Code"
                      value={mpostal}
                      onChange={(e) => setMpostal(e.target.value)}
                      name="mpostal"
                    />
                  </Form.Group>
                )}
                {mobilephonex && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Mobile Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Mobile Phone"
                      value={mmobilePhone}
                      onChange={(e) => setMmobilePhone(e.target.value)}
                      name="mmobilePhone"
                    />
                  </Form.Group>
                )}
                {workphonex && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Work Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Work Phone"
                      value={mworkPhone}
                      onChange={(e) => setMworkPhone(e.target.value)}
                      name="mworkPhone"
                    />
                  </Form.Group>
                )}

                {familyx ? <p className="formtext mt-5">Family</p> : null}
                {familyx && (
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="hed">Family</Form.Label>
                    <Form.Select
                      value={mfamily}
                      name="mfamily"
                      onChange={(e) => setMfamily(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option>Select an Option</option>
                      <option value="Head of HouseHold">
                        Head of HouseHold
                      </option>
                      <option value="Spouse">Spouse</option>
                      <option value="Adult">Adult</option>
                      <option value="Youth">Youth</option>
                      <option value="Teenager">Teenager</option>
                      <option value="Child">Child</option>
                      <option value="Unassigned">Unassigned</option>
                    </Form.Select>
                  </Form.Group>
                )}

                {facebookx || twitterx || instagramx || linkedinx ? (
                  <p className="formtext mt-5">Social Media</p>
                ) : null}

                {facebookx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Facebook</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Facebook Link"
                      value={facebook}
                      onChange={(e) => setFacebook(e.target.value)}
                      name="facebook"
                    />
                  </Form.Group>
                )}
                {twitterx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">X</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter X handle"
                      value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                      name="twitter"
                    />
                  </Form.Group>
                )}

                {instagramx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Instagram</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Instagram Handle"
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                      name="instagram"
                    />
                  </Form.Group>
                )}

                {linkedinx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">LinkedIn</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter LinkedIn Link"
                      value={linkedin}
                      onChange={(e) => setLinkedin(e.target.value)}
                      name="linkedin"
                    />
                  </Form.Group>
                )}

                {schoolx || gradex || employerx ? (
                  <p className="formtext mt-5">Education and Work</p>
                ) : null}
                {schoolx && (
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="hed">School</Form.Label>
                    <Form.Select
                      value={mschool}
                      onChange={(e) => setMschool(e.target.value)}
                      name="mschool"
                      aria-label="Default select example"
                    >
                      <option>Select an Option</option>

                      <option value="No formal education">
                        No formal education
                      </option>
                      <option value="Primary education">
                        Primary education
                      </option>
                      <option value="Secondary education">
                        Secondary education or high school
                      </option>
                      <option value="GED">GED</option>
                      <option value="Vocational qualification">
                        Vocational qualification
                      </option>
                      <option value="Bachelor's degree">
                        Bachelor's degree
                      </option>
                      <option value="Master's degree">Master's degree</option>
                      <option value="Doctorate or higher">
                        Doctorate or higher
                      </option>
                    </Form.Select>
                  </Form.Group>
                )}
                {gradex && (
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="hed">Grade</Form.Label>
                    <Form.Select
                      value={mgrade}
                      name="mgrade"
                      onChange={(e) => setMgrade(e.target.value)}
                      aria-label="Default select example"
                    >
                      <option>No Grade</option>

                      <option value="JK">JK</option>
                      <option value="SK">SK</option>
                      <option value="g1">Grade 1</option>
                      <option value="g2">Grade 2</option>
                      <option value="g3">Grade 3</option>
                      <option value="g4">Grade 4</option>
                      <option value="g5">Grade 5</option>
                      <option value="g6">Grade 6</option>
                      <option value="g7">Grade 7</option>
                      <option value="g8">Grade 8</option>
                      <option value="g9">Grade 9</option>
                      <option value="g10">Grade 10</option>
                      <option value="g11">Grade 11</option>
                      <option value="g12">Grade 12</option>
                      <option value="graduate">Graduated</option>
                    </Form.Select>
                  </Form.Group>
                )}
                {employerx && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="hed">Employer</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name of Employee"
                      value={memployer}
                      onChange={(e) => setEmployer(e.target.value)}
                      name="memployer"
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={() => createPersons({ members })}>
            Create Member
          </Button> */}
          <Button onClick={createPersons}>Create Member</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ButtonModal;

import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./people.css";

const CreatePeopleLeft = ({
  showGender,
  genderx,
  showStatus,
  statusx,
  showMartial,

  agex,
  showAge,
  familyx,
  showFamily,
  gradex,
  showGrade,
  schoolx,
  showSchool,
  employerx,
  showEmployer,
  showWork,
  showMobile,
  showAnniversary,
  anniversaryx,
  showNotes,
  notex,
  showFacebook,
  showTwitter,
  showInstagram,
  showLinkedin,
  showHome,
}) => {
  return (
    <>
      <p className="hedss">Create Member</p>
      <Col className="dwdq">
        <div>
          <p className="padt2">General Information</p>

          <Form.Check
            label="Birth Date"
            aria-label="option 3"
            onClick={showAge}
            className="wedwe"
          />
          <Form.Check
            label="Anniversary Date"
            aria-label="option 3"
            onClick={showAnniversary}
            className="wedwe"
          />

          <Form.Check
            label="Note"
            aria-label="option 2"
            onClick={showNotes}
            className="wedwe"
          />
          <Form.Check
            label="Family"
            aria-label="option 2"
            onClick={showFamily}
            className="wedwe"
          />
        </div>

        <div className="padt">
          <p className="padt2">Contact</p>

          <Form.Check
            label="Work Phone"
            aria-label="option 7"
            onClick={showWork}
            className="wedwe"
          />
          <Form.Check
            label="Home Phone"
            aria-label="option 13"
            onClick={showHome}
            className="wedwe"
          />
        </div>
        <div className="padt">
          <p className="padt2">Social Media</p>

          <Form.Check
            label="Facebook"
            aria-label="option 9"
            onClick={showFacebook}
            className="wedwe"
          />
          <Form.Check
            label="Twitter"
            aria-label="option 10"
            className="wedwe"
            onClick={showTwitter}
          />
          <Form.Check
            label="Instagram"
            aria-label="option 11"
            className="wedwe"
            onClick={showInstagram}
          />
          <Form.Check
            label="LinkedIn"
            aria-label="option 12"
            className="wedwe"
            onClick={showLinkedin}
          />
        </div>

        <div className="padt">
          <p className="padt2">Education & Work</p>
          <Form.Check
            label="School"
            aria-label="option 6"
            onClick={showSchool}
            className="wedwe"
          />
          <Form.Check
            label="Grade"
            aria-label="option 7"
            onClick={showGrade}
            className="wedwe"
          />
          <Form.Check
            label="Employer"
            aria-label="option 8"
            onClick={showEmployer}
            className="wedwe"
          />
        </div>
      </Col>
    </>
  );
};

export default CreatePeopleLeft;

import React from "react";
import "./pagebtn.css";

const PageBtn = ({ currentPage, totalPages, handlePageChange }) => {
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handlePageSelect = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  const getVisiblePages = () => {
    if (totalPages <= 3) {
      return [...Array(totalPages).keys()].map((num) => num + 1);
    } else if (currentPage === 1) {
      return [1, 2, 3];
    } else if (currentPage === totalPages) {
      return [totalPages - 2, totalPages - 1, totalPages];
    } else {
      return [currentPage - 1, currentPage, currentPage + 1];
    }
  };

  const visiblePages = getVisiblePages();

  return (
    <div className="pagination-buttons">
      <button onClick={handlePrevPage} disabled={currentPage === 1}>
        Previous
      </button>
      {visiblePages.map((num) => (
        <button
          key={num}
          onClick={() => handlePageSelect(num)}
          className={currentPage === num ? "active" : ""}
        >
          {num}
        </button>
      ))}
      <button onClick={handleNextPage} disabled={currentPage === totalPages}>
        Next
      </button>
    </div>
  );
};

export default PageBtn;

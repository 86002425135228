import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAppContext } from "../../context/appContext";
import QRCode from "qrcode.react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";

const AddShareLink = ({ show, setShow, onSuccessfulDelete }) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { addWorkflow, user } = useAppContext();
  const [workflow, setWorkflow] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [copiedShortedUrl, setCopiedShortedUrl] = useState(false);
  const [error, setError] = useState(false);
  const [shortenedUrl, setShortenedUrl] = useState("");
  const [churchName, setChurchName] = useState("");

  useEffect(() => {
    if (user && user.churchName) {
      setChurchName(user?.churchName);
    }
  }, [user]);

  //const baseURL = "http://192.168.1.157:3000/church";
  const baseURL = "https://churchease.com/church";
  //const baseURL = "https://staging.churchease.com/church";
  const actualURL = `${baseURL}/${encodeURIComponent(churchName)}`;

  useEffect(() => {
    fetchShortenedUrl();
  }, [baseURL, show]);

  const fetchShortenedUrl = async () => {
    const res = await axios.get(
      `https://tinyurl.com/api-create.php?url=${encodeURIComponent(actualURL)}`
    );
    setShortenedUrl(res.data);
  };

  const handleSave = () => {
    if (!workflow) {
      setError(true);
      return;
    }
    const newWorkflow = {
      name: workflow,
      description: workflowDescription,
      churchName: user.churchName,
    };

    addWorkflow(newWorkflow);
    setWorkflow("");
    setWorkflowDescription("");
    onSuccessfulDelete();
    handleClose();
  };

  const handleCopy = () => {
    setCopiedShortedUrl(true);
    // Optional: Hide the prompt after a few seconds
    setTimeout(() => setCopiedShortedUrl(false), 2000); // Hide after 2 seconds
  };
  //console.log("shortenedUrl", shortenedUrl);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Share Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {copiedShortedUrl && (
          <div style={{ marginTop: "10px" }} className="inlinesuccess">
            Copied!
          </div>
        )}
        <Form.Label htmlFor="inputPassword5">Link</Form.Label>
        <div className="flex flex-row gap-3">
          <Form.Control
            type="text"
            value={actualURL}
            disabled
            onChange={(e) => setWorkflow(e.target.value)}
          />

          <CopyToClipboard text={actualURL} onCopy={handleCopy}>
            <button className="jiluil">Copy</button>
          </CopyToClipboard>
        </div>
        <Form.Label className="mt-3" htmlFor="inputPassword5">
          Shortened Link
        </Form.Label>
        <div className="flex flex-row gap-3">
          <Form.Control
            type="text"
            value={shortenedUrl}
            //onChange={(e) => setWorkflowDescription(e.target.value)}
            disabled
          />

          <CopyToClipboard text={shortenedUrl} onCopy={handleCopy}>
            <button className="jiluil3">Copy</button>
          </CopyToClipboard>
        </div>

        <div className="mt-3 mb-3">
          {shortenedUrl && <QRCode value={shortenedUrl} />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClose}>
          Close
        </button>
        <button className="jiluil3" onClick={handleSave}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddShareLink;

import React, { useState, useEffect } from "react";
import { Select, Space } from "antd";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./people.css";
import { useNavigate } from "react-router-dom";
import ButtonModal from "../../../components/ButtonModal/ButtonModal";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import { FaPlusCircle, FaPlus, FaPlaceH } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import CustomAlert from "../../../components/CustomAlert/CustomAlert";
import Loading from "../../../components/Loading/Loading";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import Table from "react-bootstrap/Table";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuid } from "uuid";
import blank2 from "../../../assets/images/blank2.png";
import EditModal from "../../../components/ButtonModal/EditModal";
import { nanoid } from "nanoid";

const CreatePeopleMiddle = ({
  genderx,
  statusx,

  agex,
  anniversaryx,
  familyx,
  schoolx,
  employerx,
  gradex,
  workPhonex,
  mobilePhonex,
  notex,
  facebookx,
  twitterx,
  instagramx,
  linkedinx,
  homePhonex,
}) => {
  const {
    showAlert,
    displayAlert,
    handleChange,
    clearValues,
    createMember,
    memail,
    mgrade,
    campus,
    mschool,
    mfirstName,
    preferredName,
    mlastName,
    mgender,
    mstatus,
    mAnniversaryDay,
    mAnniversaryMonth,
    mBirthMonth,
    mBirthday,
    mmarital,
    mage,
    manniversary,
    mnotes,
    mhomePhone,
    mworkPhone,
    memployer,
    mmobilePhone,
    mcity,
    maddress,
    maddress2,
    mpostal,
    mstate,
    mProfilePicture,
    logoutUser,
    user,
    familyRole,
    getCompleteMembers,
    facebook,
    twitter,
    instagram,
    linkedin,
    getContainers,
    containers,
    tags,
    isLoading,
    churchDomain,
    completemembers,
  } = useAppContext();

  useEffect(() => {
    getCompleteMembers();
  }, []);

  useEffect(() => {
    getContainers();
  }, []);

  const [isFree, setIsFree] = useState(false);
  const [dynamicFields, setDynamicFields] = useState([]);

  useEffect(() => {
    if (user.subscription && user.subscription.length > 0) {
      if (user.subscription[0].plan.nickname === "Free") {
        setIsFree(true);
      } else {
        setIsFree(false);
      }
    }
  }, [user.subscription]);

  useEffect(() => {
    getDynamicFields();
  }, []);

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );
  // const unique_id = uuid();
  const unique_id = uuid();
  const unique_id_2 = uuid();

  const familyId = unique_id.slice(0, 8);
  const familySecCode = unique_id_2.slice(0, 8);

  const [familyData, setFamilyData] = useState([]);
  const [existMembers, setExistMembers] = useState([]);
  const [image, setImage] = useState({});
  const [error, setError] = useState(false);
  const [preview, setPreview] = useState(blank2); // Replace with your placeholder image URL

  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");

  const handleImage = (e) => {
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));
    setUploadButtonText(file.name);

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        // Maximum dimensions
        const maxWidth = 100;
        const maxHeight = 120;
        let newWidth = img.width;
        let newHeight = img.height;

        // Calculate the width and height, maintaining aspect ratio
        if (newWidth > maxWidth) {
          newHeight = (maxWidth / newWidth) * newHeight;
          newWidth = maxWidth;
        }
        if (newHeight > maxHeight) {
          newWidth = (maxHeight / newHeight) * newWidth;
          newHeight = maxHeight;
        }

        // Resize the image
        Resizer.imageFileResizer(
          file,
          newWidth,
          newHeight,
          "JPEG",
          100,
          0,
          async (uri) => {
            try {
              let { data } = await authFetch.post("/members/upload-image", {
                image: uri,
              });

              setImage(data);
            } catch (err) {
              console.error(err);
            }
          }
        );
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleImageRemove = async () => {
    try {
      await authFetch.post("/members/remove-image", { image });
      setImage({});
      setPreview(blank2);
      setUploadButtonText("Upload Removed");
    } catch (err) {}
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const updatefam = (e, familyd, index) => {
    setSelectedMember(familyd);
    setShowEditModal(true);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    if (!mfirstName || !mlastName || !mstatus || !memail || !mgender) {
      setError(true);
      return;
    }

    // Prepare dynamic fields data
    const preparedDynamicFields = dynamicFields.map((fieldGroup) => ({
      title: fieldGroup.title,
      fields: fieldGroup.fields.map((field) => ({
        title: field.title,
        type: field.type,
        response: field.response,
        // Include 'options' for 'radio' and 'checkbox' types if necessary
        options:
          field.type === "radio" || field.type === "checkbox"
            ? field.options
            : undefined,
      })),
    }));

    // familyData.forEach((member) => {
    //   member.familyId = familyId;
    //   member.familySecCode = familySecCode;
    // });
    //const shortCode = nanoid(6).toUpperCase();
    familyData.forEach((member) => {
      member.familyId = familyId;
      member.familySecCode = familySecCode;
    });

    existMembers.forEach((member) => {
      member.familyId = familyId;
      member.familySecCode = familySecCode;
    });

    let newMemberData = {
      // passCode: shortCode,
      mfirstName: mfirstName,
      preferredName: preferredName,
      mlastName: mlastName,
      memail: memail,
      mgender: mgender,
      mstatus: mstatus,
      mmarital: mmarital,
      mage: mage,
      manniversary: manniversary,
      mschool: mschool,
      mgrade: mgrade,
      campus: campus,
      mfamily: [...familyData, ...existMembers],
      mhomePhone: mhomePhone,
      mworkPhone: mworkPhone,
      dynamicFields: preparedDynamicFields,
      memployer: memployer,
      mmobilePhone: mmobilePhone,
      mcity: mcity,
      mnotes: mnotes,
      maddress: maddress,
      maddress2: maddress2,
      mpostal: mpostal,
      mstate: mstate,
      mProfilePicture: image,
      mAnniversaryDay: mAnniversaryDay,
      mAnniversaryMonth: mAnniversaryMonth,
      mBirthMonth: mBirthMonth,
      mBirthdayCont: mBirthMonth + " " + mBirthday,
      mAnniversaryCont: mAnniversaryMonth + " " + mAnniversaryDay,
      mBirthday: mBirthday,
      churchName: user.churchName,
      familySecCode: uniInformation?.familySecCode
        ? uniInformation?.familySecCode
        : familySecCode,
      familyId: uniInformation?.familyId ? uniInformation?.familyId : familyId,
      familyRole: familyRole,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      churchDomain: user?.churchDomain,
      linkedin: linkedin,
      tags: tags,
      role: [{ roleName: "Standard" }],
    };

    // newMemberData.familyId = familyId;
    // newMemberData.familySecCode = familySecCode;

    // existMembers.forEach((member) => {
    //   member.familyId = familyId;
    //   member.familySecCode = familySecCode;
    // });

    let allFamilyData = [...familyData, ...existMembers, newMemberData];

    let currentMember = { ...newMemberData, mfamily: allFamilyData };

    for (let i = 0; i < familyData?.length; i++) {
      createMemberFromFamily(familyData[i], allFamilyData);
    }

    createMember({ currentMember });

    navigate("/auth/people");

    clearValues();
  }

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);

  const showAddMember = () => {
    setShow2(true);
    setShow(false);
  };

  const createMemberFromFamily = async (e, allFamilyData) => {
    // if (
    //   !e?.mfirstName ||
    //   !e?.mlastName ||
    //   !e?.memail ||
    //   !e?.mgender ||
    //   !e.mstatus ||
    //   !e?.mmarital ||
    //   !e?.mmobilePhone ||
    //   !e?.mcity ||
    //   !e?.maddress ||
    //   !e?.mpostal ||
    //   !e?.mstate
    // ) {
    //   setError(true);
    //   return;
    // }

    // Prepare dynamic fields data
    const preparedDynamicFields = dynamicFields.map((fieldGroup) => ({
      title: fieldGroup.title,
      fields: fieldGroup.fields.map((field) => ({
        title: field.title,
        type: field.type,
        response: field.response,
        // Include 'options' for 'radio' and 'checkbox' types if necessary
        options:
          field.type === "radio" || field.type === "checkbox"
            ? field.options
            : undefined,
      })),
    }));

    const currentMember = {
      mfirstName: e?.mfirstName || "",
      preferredName: e?.preferredName || "",
      mlastName: e?.mlastName || "",
      preferredName: e?.preferredName || "",
      memail: e?.memail || "",
      mgender: e?.mgender || "",
      mstatus: e?.mstatus || "",
      mmarital: e?.mmarital || "",
      mage: e?.mage || "",
      manniversary: e?.manniversary || "",
      mschool: e?.mschool || "",
      mgrade: e?.mgrade || "",
      campus: e?.campus || "",
      mfamily: allFamilyData || [],
      tags: e?.tags || [],
      dynamicFields: preparedDynamicFields,
      //dynamicFields: e?.dynamicFields || [],
      mhomePhone: e?.mhomePhone || "",
      mworkPhone: e?.mworkPhone || "",
      memployer: e?.memployer || "",
      mmobilePhone: e?.mmobilePhone || "",
      mcity: e?.mcity || "",
      maddress: e?.maddress || "",
      maddress2: e?.maddress2 || "",
      mpostal: e?.mpostal || "",
      mstate: e?.mstate || "",
      mProfilePicture: e?.image || "",
      mnotes: e?.mnotes || "",
      mAnniversaryDay: e?.mAnniversaryDay || "",
      mAnniversaryMonth: e?.mAnniversaryMonth || "",
      mBirthMonth: e?.mBirthMonth || "",
      mBirthdayCont: e?.mBirthMonth + " " + e?.mBirthday || "",
      mAnniversaryCont: e?.mAnniversaryMonth + " " + e?.mAnniversaryDay || "",
      mBirthday: e?.mBirthday || "",
      churchName: user?.churchName || "",
      familyId: familyId || "",
      familySecCode: familySecCode || "",
      familyRole: e?.familyRole || "",
      facebook: e?.facebook || "",
      churchDomain: user?.churchDomain || "",
      twitter: e?.twitter || "",
      instagram: e?.instagram || "",
      linkedin: e?.linkedin || "",
      tags: e?.tags || [],
      role: "Standard",
    };

    try {
      await createMember({ currentMember });
    } catch (error) {
      console.error(error);
    }
    // createMember({ currentMember });
    // navigate("/auth/people");
  };

  const handleMemberInput = (e) => {
    let name, value;

    if (Array.isArray(e)) {
      name = "tags"; // Assuming 'tags' is the name for the Select component
      value = e;
    } else {
      // For regular input events
      name = e.target.name;
      value = e.target.value;
    }

    handleChange({ name, value });
  };

  const handleFieldChange = (dynamicIndex, fieldIndex, value) => {
    setDynamicFields((currentFields) => {
      const updatedFields = [...currentFields];
      const updatedField = {
        ...updatedFields[dynamicIndex].fields[fieldIndex],
        response: value,
      };
      updatedFields[dynamicIndex].fields[fieldIndex] = updatedField;
      return updatedFields;
    });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const navigate = useNavigate();
  const cancel = () => {
    navigate("/auth/people");
  };

  function handleShow() {
    setShow(true);
  }

  function handleShow1() {
    setShow1(true);
  }

  function handleFamilyData(data) {
    setFamilyData((currentFamily) => [...currentFamily, ...data]);
  }

  function handleFamilyDataUpdated(updatedMember) {
    //  console.log("Updating member with ID:", updatedMember.tempId);
    setFamilyData((currentFamily) => {
      //   console.log("Current family data:", currentFamily);

      const updatedFamilyData = currentFamily.map((member) => {
        if (member.tempId === updatedMember.tempId) {
          // console.log("Found matching ID for member:", member);
          return updatedMember;
        }
        return member;
      });

      //  console.log("Updated family data:", updatedFamilyData);
      return updatedFamilyData;
    });
  }

  const removefam = (index) => {
    let fil = familyData.filter((_, i) => i !== index);

    setFamilyData(fil);
  };

  const transformedContainers = containers.map((container) => ({
    ...container,
    label: container.name, // Setting the label equal to the name field
    value: container.name, // Setting the id equal to the _id field
  }));

  const getDynamicFields = async () => {
    try {
      const { data } = await authFetch.get("/dynamicfields");

      // Check if the response is in the expected format
      if (data) {
        setDynamicFields(data.data);
      }
    } catch (error) {
      console.error("Failed to fetch dynamic fields:", error);
      // Handle the UI update for error state here, if necessary
      // e.g., setErrorState(true) or similar
    }
  };

  const [searchData, setSearchData] = useState("");
  const [familyInfo, setFamilyInfo] = useState([]);
  const onSearch = (searchTerm) => {
    setSearchData(searchTerm);
  };

  const [selectedUserId, setSelectedUserId] = useState("");
  const [uniInformation, setUniInformation] = useState(null);

  const handleAddFamilyMember = async () => {
    // console.log("Selected user ID:", selectedUserId);
    try {
      const { data } = await authFetch(
        `/members/getmemberbyid/${selectedUserId}`
      );

      const [uniquememberdetails] = data;
      setUniInformation(uniquememberdetails);

      handleClose2();
    } catch (error) {
      console.error("Failed to fetch member by ID:", error);
    }
  };
  const clearUniInformation = () => {
    setUniInformation(null);
  };

  return (
    <>
      {showAlert && <Alert />}
      <Col style={{ paddingTop: "5.5rem" }}>
        {error && (
          <CustomAlert
            alertText="Please fill all required fields to continue!"
            alertType="danger"
          />
        )}
        <p className="formtext">General Information</p>
        <div className="creaaaa">
          <Form.Group
            className="flex items-center justify-center flex-column"
            controlId="formBasicEmail"
            style={{ paddingBottom: "3rem", paddingTop: "1rem" }}
          >
            <div style={{ paddingBottom: 20 }}>
              {preview && (
                <img
                  src={preview}
                  alt="Uploaded image"
                  width="100"
                  style={{
                    borderRadius: "50%",
                    paddingBottom: "0.6rem",
                    border: "1px solid #BDBDBD",
                  }}
                />
              )}
            </div>
            <Form.Label className="hed btn addfambut2 btn-block">
              Upload Picture
            </Form.Label>

            <Form.Control
              type="file"
              value={mProfilePicture}
              name="image"
              onChange={handleImage}
              accept="image/*"
              className="formvalue"
              hidden
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">
              First Name <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              value={mfirstName}
              name="mfirstName"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              className="formvalue"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">Preferred Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Preferred Name"
              value={preferredName}
              name="preferredName"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              className="formvalue"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="hed">
              Last Name <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              className="formvalue"
              placeholder="Enter Last Name"
              value={mlastName}
              name="mlastName"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <p className="hed">Gender</p>

            <Form.Check
              type="radio"
              label="Male"
              name="mgender"
              aria-label="radio 1"
              value="Male"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              required
            />

            <Form.Check
              type="radio"
              label="Female"
              name="mgender"
              aria-label="radio 1"
              value="Female"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="hed">
              Status <span className="myast">*</span>
            </Form.Label>
            <Form.Select
              value={mstatus}
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              name="mstatus"
              aria-label="Default select example"
              className="formvalue"
              required
            >
              <option>Select an Option</option>

              <option value="Member">Member</option>
              <option value="Visitor">Visitor</option>
              <option value="Leader">Leader</option>
              <option value="Archived">No Longer Attends</option>
              <option value="Worker">Worker</option>
              <option value="Minister">Minister</option>
              <option value="Pastor">Pastor</option>
              <option value="Volunteer">Volunteer</option>
            </Form.Select>
          </Form.Group>
          {agex && (
            <div>
              <Form.Label className="hed">Birth Date</Form.Label>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="number"
                      min="1"
                      max="31"
                      value={mBirthday}
                      className="formvalue"
                      //handleChange={handleMemberInput}
                      onChange={handleMemberInput}
                      name="mBirthday"
                      placeholder="Day"
                      style={{ width: 100 }}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Select
                      value={mBirthMonth}
                      //handleChange={handleMemberInput}
                      onChange={handleMemberInput}
                      name="mBirthMonth"
                      aria-label="Default select example"
                      className="formvalue"
                      style={{ width: 150 }}
                    >
                      <option>Month</option>

                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            </div>
          )}
          {anniversaryx && (
            <div>
              <Form.Label className="hed">Wedding Anniversary</Form.Label>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="number"
                      min="1"
                      max="31"
                      value={mAnniversaryDay}
                      className="formvalue"
                      //handleChange={handleMemberInput}
                      onChange={handleMemberInput}
                      name="mAnniversaryDay"
                      placeholder="Day"
                      style={{ width: 100 }}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Select
                      value={mAnniversaryMonth}
                      // handleChange={handleMemberInput}
                      onChange={handleMemberInput}
                      name="mAnniversaryMonth"
                      aria-label="Default select example"
                      className="formvalue"
                      style={{ width: 150 }}
                    >
                      <option>Month</option>

                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            </div>
          )}
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <p className="hed">Marital Status</p>
            <Form.Check
              type="radio"
              label="Single"
              name="mmarital"
              aria-label="radio 1"
              value="Single"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
            />
            <Form.Check
              type="radio"
              label="Engaged"
              name="mmarital"
              aria-label="radio 1"
              value="Engaged"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
            />
            <Form.Check
              type="radio"
              label="Married"
              name="mmarital"
              aria-label="radio 1"
              value="Married"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
            />
            <Form.Check
              type="radio"
              label="Divorced"
              name="mmarital"
              aria-label="radio 1"
              value="Divorced"
              // handleChange={handleMemberInput}
              onChange={handleMemberInput}
            />
            <Form.Check
              type="radio"
              label="Widowed"
              name="mmarital"
              aria-label="radio 1"
              value="Widowed"
              // handleChange={handleMemberInput}
              onChange={handleMemberInput}
            />
            <Form.Check
              type="radio"
              label="Separated"
              name="mmarital"
              aria-label="radio 1"
              value="Separated"
              // handleChange={handleMemberInput}
              onChange={handleMemberInput}
            />
            <Form.Check
              type="radio"
              label="Other"
              name="mmarital"
              aria-label="radio 1"
              value="Other"
              // handleChange={handleMemberInput}
              onChange={handleMemberInput}
            />
          </Form.Group>
          {notex && (
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Additional Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={mnotes}
                name="mnotes"
                onChange={handleMemberInput}
              />
            </Form.Group>
          )}
        </div>

        {familyx && (
          <>
            <p className="formtext">Family</p>
            <div className="creaaaa">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="hed">Family</Form.Label>
                <Form.Select
                  value={familyRole}
                  // handleChange={handleMemberInput}
                  onChange={handleMemberInput}
                  name="familyRole"
                  aria-label="Default select example"
                  className="formvalue"
                  required
                >
                  <option>Select an Option</option>
                  <option value="Head of HouseHold">Head of HouseHold</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Adult">Adult</option>
                  <option value="Youth">Youth</option>
                  <option value="Teenager">Teenager</option>
                  <option value="Child">Child</option>
                  <option value="Unassigned">Unassigned</option>
                </Form.Select>
              </Form.Group>
              <div>
                <Table striped bordered hover>
                  {!![...familyData, ...existMembers]?.length &&
                    [...familyData, ...existMembers]?.map((familyd, index) => {
                      return (
                        <tbody>
                          <tr>
                            <td>{familyd.mfirstName}</td>
                            <td>{familyd.mlastName}</td>
                            <td>{familyd.preferredName}</td>
                            <td>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Select
                                  value={familyd.mfamily}
                                  name="mfamily"
                                  //handleChange={handleMemberInput}
                                  onChange={handleMemberInput}
                                  aria-label="Default select example"
                                  className="formvalue"
                                >
                                  <option>{familyd.mfamily}</option>
                                  <option value="1">Head of Household</option>
                                  <option value="2">Spouse</option>
                                  <option value="3">Adult</option>
                                  <option value="4">Child</option>
                                  <option value="5">Unassigned</option>
                                </Form.Select>
                              </Form.Group>
                            </td>
                            <td>
                              <Tooltip title="Update">
                                <FontAwesomeIcon
                                  icon={faPen}
                                  className="dashicon"
                                  onClick={(e) => updatefam(e, familyd, index)}
                                />
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title="Delete">
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="dashicon"
                                  onClick={() => removefam(index)}
                                />
                              </Tooltip>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}

                  {uniInformation && (
                    <>
                      <p style={{ fontWeight: "bold" }}>Add to this family</p>
                      <div className="flex gap-5 align-center mb-3">
                        <p>
                          {(uniInformation.mfirstName || "") +
                            " " +
                            (uniInformation.mlastName || "")}
                        </p>

                        <p
                          onClick={clearUniInformation}
                          style={{
                            fontWeight: "600",
                            color: "var(--primary-color)",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                            textDecoration: "underline",
                          }}
                        >
                          Delete
                        </p>
                      </div>
                    </>
                  )}
                </Table>
              </div>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Button
                  className="addfambut"
                  onClick={handleShow}
                  style={{ marginBottom: "1px" }}
                >
                  Add Family Member
                </Button>
              </Form.Group>
              <Modal
                show={show}
                onHide={handleClose}
                centered
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{ fontSize: "100%", fontWeight: "600" }}>
                    Add New Family Members
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="flex flex-column items-center justify-center my-5">
                    <ButtonModal
                      labels="Add Family Member"
                      handler={handleFamilyData}
                      closemodal={handleClose}
                      className="mt-5"
                      style="dereerre"
                    />
                    <p className="text-center spdm">
                      Add a new person to the database as a member of this
                      family
                    </p>
                  </div>

                  <div className="flex justify-center flex-column w-100">
                    <button className="famils" onClick={showAddMember}>
                      Add To Existing Family
                    </button>

                    <p className="text-center spdm">
                      Add an existing members to this family.
                    </p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="jiluil" onClick={handleClose}>
                    Close
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
        )}

        {facebookx || twitterx || instagramx || linkedinx ? (
          <p className="formtext">Social Media</p>
        ) : null}

        {facebookx && (
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">Facebook</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Facebook"
              value={facebook}
              name="facebook"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              className="formvalue"
            />
          </Form.Group>
        )}

        {twitterx && (
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">Twitter</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Twitter"
              value={twitter}
              name="twitter"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              className="formvalue"
            />
          </Form.Group>
        )}
        {instagramx && (
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">Instagram</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Instagram"
              value={instagram}
              name="instagram"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              className="formvalue"
            />
          </Form.Group>
        )}
        {linkedinx && (
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="hed">LinkedIn</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter LinkedIn"
              value={linkedin}
              name="linkedin"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              className="formvalue"
            />
          </Form.Group>
        )}

        <p className="formtext">Contact Information</p>
        <div className="creaaaa">
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="hed">Mobile Phone</Form.Label>
            <Form.Control
              type="text"
              className="formvalue"
              placeholder="Enter Mobile Phone"
              value={mmobilePhone}
              // handleChange={handleMemberInput}
              onChange={handleMemberInput}
              name="mmobilePhone"
              required
            />
          </Form.Group>

          {homePhonex && (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="hed">Home Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Home Phone"
                value={mhomePhone}
                className="formvalue"
                //handleChange={handleMemberInput}
                onChange={handleMemberInput}
                name="mhomePhone"
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="hed">
              Email Address <span className="myast">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              className="formvalue"
              placeholder="Enter Email Address"
              value={memail}
              // handleChange={handleMemberInput}
              onChange={handleMemberInput}
              name="memail"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="hed">Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              className="formvalue"
              value={maddress}
              // handleChange={handleMemberInput}
              onChange={handleMemberInput}
              name="maddress"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="hed">Address Line 2</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address line 2"
              className="formvalue"
              value={maddress2}
              // handleChange={handleMemberInput}
              onChange={handleMemberInput}
              name="maddress2"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="hed">City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter City"
              value={mcity}
              className="formvalue"
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              name="mcity"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="hed">State</Form.Label>
            <Form.Control
              type="text"
              className="formvalue"
              placeholder="Enter State"
              value={mstate}
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              name="mstate"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="hed">Postal Code</Form.Label>
            <Form.Control
              type="text"
              className="formvalue"
              placeholder="Enter Postal Code"
              value={mpostal}
              //handleChange={handleMemberInput}
              onChange={handleMemberInput}
              name="mpostal"
              required
            />
          </Form.Group>
          {workPhonex && (
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="hed">Work Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Work Phone"
                className="formvalue"
                value={mworkPhone}
                // handleChange={handleMemberInput}
                onChange={handleMemberInput}
                name="mworkPhone"
              />
            </Form.Group>
          )}

          {dynamicFields.map((dynamicField, dynamicIndex) => (
            <div key={dynamicIndex}>
              <p className="formtext">{dynamicField.title}</p>
              {dynamicField.fields.map((field, fieldIndex) => (
                <Form.Group key={fieldIndex} className="mb-3">
                  <Form.Label htmlFor={`field-${dynamicIndex}-${fieldIndex}`}>
                    {field.title}
                  </Form.Label>

                  {field.type === "text" && (
                    <Form.Control
                      id={`field-${dynamicIndex}-${fieldIndex}`}
                      type="text"
                      className="formvalue"
                      placeholder={`Enter ${field.title}`}
                      value={field.response || ""}
                      onChange={(e) =>
                        handleFieldChange(
                          dynamicIndex,
                          fieldIndex,
                          e.target.value
                        )
                      }
                      required
                    />
                  )}

                  {field.type === "textarea" && (
                    <Form.Control
                      id={`field-${dynamicIndex}-${fieldIndex}`}
                      as="textarea"
                      rows={3}
                      placeholder={`Enter ${field.title}`}
                      value={field.response || ""}
                      onChange={(e) =>
                        handleFieldChange(
                          dynamicIndex,
                          fieldIndex,
                          e.target.value
                        )
                      }
                      required
                    />
                  )}

                  {field.type === "radio" &&
                    field.options.map((option, optionIndex) => (
                      <Form.Check
                        key={`${dynamicIndex}-${fieldIndex}-${optionIndex}`}
                        type="radio"
                        id={`radio-${dynamicIndex}-${fieldIndex}-${optionIndex}`}
                        label={option}
                        value={option} // Ensure this is set correctly
                        name={`radioGroup-${dynamicIndex}-${fieldIndex}`}
                        checked={option === field.response}
                        onChange={(e) =>
                          handleFieldChange(
                            dynamicIndex,
                            fieldIndex,
                            e.target.value
                          )
                        }
                        required
                      />
                    ))}
                  {field.type === "checkbox" &&
                    field.options.map((option, optionIndex) => (
                      <Form.Check
                        key={`${dynamicIndex}-${fieldIndex}-${optionIndex}`}
                        type="checkbox"
                        id={`checkbox-${dynamicIndex}-${fieldIndex}-${optionIndex}`}
                        label={option}
                        value={option} // Ensure this is set correctly
                        name={`checkboxGroup-${dynamicIndex}-${fieldIndex}`}
                        checked={field.response?.includes(option)} // Check if the option is in the response array
                        onChange={(e) => {
                          // Determine the new array of selected options based on the checkbox's checked state
                          const updatedOptions = e.target.checked
                            ? [...(field.response || []), option] // Add option if checked
                            : field.response.filter((o) => o !== option); // Remove option if unchecked
                          handleFieldChange(
                            dynamicIndex,
                            fieldIndex,
                            updatedOptions
                          );
                        }}
                        required
                      />
                    ))}
                </Form.Group>
              ))}
            </div>
          ))}
          {mschool || mgrade || memployer ? (
            <p className="formtext">Education and Work</p>
          ) : null}
          {schoolx && (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="hed">School</Form.Label>
              <Form.Select
                value={mschool}
                // handleChange={handleMemberInput}
                onChange={handleMemberInput}
                name="mschool"
                aria-label="Default select example"
                className="formvalue"
              >
                <option>Select an Option</option>

                <option value="No formal education">No formal education</option>
                <option value="Primary education">Primary education</option>
                <option value="Secondary education">
                  Secondary education or high school
                </option>
                <option value="GED">GED</option>
                <option value="Vocational qualification">
                  Vocational qualification
                </option>
                <option value="Bachelor's degree">Bachelor's degree</option>
                <option value="Master's degree">Master's degree</option>
                <option value="Doctorate or higher">Doctorate or higher</option>
              </Form.Select>
            </Form.Group>
          )}
          {gradex && (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="hed">Grade</Form.Label>
              <Form.Select
                value={mgrade}
                className="formvalue"
                //  handleChange={handleMemberInput}
                aria-label="Default select example"
                onChange={handleMemberInput}
                name="mgrade"
              >
                <option>Select an Option</option>
                <option value="nograde">No Grade</option>
                <option value="JK">JK</option>
                <option value="SK">SK</option>
                <option value="g1">Grade 1</option>
                <option value="g2">Grade 2</option>
                <option value="g3">Grade 3</option>
                <option value="g4">Grade 4</option>
                <option value="g5">Grade 5</option>
                <option value="g6">Grade 6</option>
                <option value="g7">Grade 7</option>
                <option value="g8">Grade 8</option>
                <option value="g9">Grade 9</option>
                <option value="g10">Grade 10</option>
                <option value="g11">Grade 11</option>
                <option value="g12">Grade 12</option>
                <option value="graduate">Graduated</option>
              </Form.Select>
            </Form.Group>
          )}
          {employerx && (
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="hed">Employer</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name of Employee"
                className="formvalue"
                value={memployer}
                //  handleChange={handleMemberInput}
                onChange={handleMemberInput}
                name="memployer"
              />
            </Form.Group>
          )}
        </div>
        <p className="formtext">Assign To Groups</p>
        <div className="creaaaa">
          <Form.Label className="hed">Add To Group</Form.Label>
          <Space
            style={{
              width: "100%",
            }}
            direction="vertical"
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select"
              value={tags}
              onChange={handleMemberInput}
              name="tags"
              options={transformedContainers}
              className="formvalue"
              disabled={isFree}
            />
          </Space>
          {user.churchCampuses && (
            <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
              <Form.Label className="hed">Campus</Form.Label>
              <Form.Select
                value={campus}
                className="formvalue"
                //  handleChange={handleMemberInput}
                aria-label="Default select example"
                onChange={handleMemberInput}
                name="campus"
              >
                <option>Select an Option</option>
                {user.churchCampuses.map((campus) => (
                  <option value={campus}>{campus.campusName}</option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </div>

        <Form.Group
          className="mb-3 flex gap-3 justify-center"
          controlId="formBasicPassword"
        >
          <button className="jiluil3" onClick={cancel}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleSubmit}>
            {isLoading ? <Loading /> : "Add Member"}
          </button>
        </Form.Group>
      </Col>

      <EditModal
        handlerUpdate={handleFamilyDataUpdated}
        closemodal={() => setShowEditModal(false)}
        data={selectedMember}
        showEditModal={showEditModal}
      />

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Add To Existing Family</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="Enter Family Name"
                autoFocus
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
                name="searchData"
              />
              <div className="dropdownmy">
                {completemembers
                  ?.filter((item) => {
                    const searchTerm = searchData?.toLowerCase();

                    const fullname =
                      item.mfirstName?.toLowerCase() +
                      item.mlastName?.toLowerCase();
                    return (
                      searchTerm &&
                      fullname?.includes(searchTerm) &&
                      fullname !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item) => (
                    <div
                      onClick={() => {
                        onSearch(`${item.mfirstName} ${item.mlastName}`);
                        setSelectedUserId(item._id);
                      }}
                      className="dropdownmy-row ewfwefwefwe"
                      key={item.mfirstName}
                    >
                      {item.mfirstName} {item.mlastName}
                    </div>
                  ))}
              </div>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Select
                value={familyInfo}
                name="familyInfo"
                onChange={(e) => setFamilyInfo(e.target.value)}
                aria-label="Default select example"
                className="formvalue"
              >
                <option>Select Family Role</option>
                <option value="Head of Household">Head of Household</option>
                <option value="Spouse">Spouse</option>
                <option value="Adult">Adult</option>
                <option value="Child">Child</option>
                <option value="Unassigned">Unassigned</option>
              </Form.Select>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil" onClick={handleClose2}>
            Close
          </button>
          <button className="jiluil3" onClick={handleAddFamilyMember}>
            Add Family Member
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreatePeopleMiddle;

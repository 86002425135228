import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./homeintro.css";

import mobww from "../../assets/newi/mobww.png";

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Banner } from "../Banner/Banner";

const HomeIntro = () => {
  return (
    <>
      <Row
        className="topdiv container22"
        style={{ paddingBottom: "6rem", paddingTop: "6rem" }}
      >
        <Col sm={12} xs={12} md={12} lg={6} className="toptext">
          <p className="homefont">
            Automate <span>Church Management</span> Seamlessly
          </p>

          <p className="hometext">
            Our intuitive online platform enables seamless automation of
            operations, effortless member management, and deepens community
            engagement.
          </p>
          <p className="mt-2 dsd">
            <Link to="/signuptrial">
              <button className="homebuttonmain">90-Days Free Trial</button>
            </Link>

            <a
              href="https://calendly.com/churchease/demo-churchease"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="homebuttonxmain">Book a Demo</button>
            </a>
          </p>
        </Col>

        <Col xs={12} sm={12} md={12} lg={6} className="topimg">
          <Image fluid src={mobww} className="imgnew2" />
        </Col>
      </Row>

      <Row className="flex items-center justify-center">
        <Banner speed={90000} />
      </Row>
    </>
  );
};

export default HomeIntro;

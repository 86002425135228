import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import "./people.css";
import CreatePeopleLeft from "./CreatePeopleLeft";
import CreatePeopleMiddle from "./CreatePeopleMiddle";
import CreatePeopleRight from "./CreatePeopleRight";

const CreatePeople = () => {
  const [genderx, setGenderx] = useState("");
  const showGender = () => {
    setGenderx(!genderx);
  };

  const [statusx, setStatusx] = useState("");
  const showStatus = () => {
    setStatusx(!statusx);
  };

  const [maritalx, setMaritalx] = useState("");
  const showMartial = () => {
    setMaritalx(!maritalx);
  };

  const [notex, setNotex] = useState("");
  const showNotes = () => {
    setNotex(!notex);
  };
  const [familyx, setFamilyx] = useState("");
  const showFamily = () => {
    setFamilyx(!familyx);
  };

  const [agex, setAgex] = useState("");
  const showAge = () => {
    setAgex(!agex);
  };

  const [anniversaryx, setAnniversaryx] = useState("");
  const showAnniversary = () => {
    setAnniversaryx(!anniversaryx);
  };

  const [gradex, setGradex] = useState("");
  const showGrade = () => {
    setGradex(!gradex);
  };
  const [schoolx, setSchoolx] = useState("");
  const showSchool = () => {
    setSchoolx(!schoolx);
  };

  const [employerx, setEmployerx] = useState("");
  const showEmployer = () => {
    setEmployerx(!employerx);
  };

  const [workPhonex, setworkPhonex] = useState("");
  const showWork = () => {
    setworkPhonex(!workPhonex);
  };

  const [homePhonex, sethomePhonex] = useState("");
  const showHome = () => {
    sethomePhonex(!homePhonex);
  };

  const [mobilePhonex, setmobilePhonex] = useState("");
  const showMobile = () => {
    setmobilePhonex(!mobilePhonex);
  };

  const [facebookx, setFacebookx] = useState("");
  const showFacebook = () => {
    setFacebookx(!facebookx);
  };

  const [twitterx, setTwitterx] = useState("");
  const showTwitter = () => {
    setTwitterx(!twitterx);
  };

  const [instagramx, setInstagramx] = useState("");
  const showInstagram = () => {
    setInstagramx(!instagramx);
  };

  const [linkedinx, setLinkedinx] = useState("");
  const showLinkedin = () => {
    setLinkedinx(!linkedinx);
  };

  return (
    <>
      <Container fluid className="create-container flex flex-row">
        <Col className="dashboard-left" sm={3}>
          <CreatePeopleLeft
            genderx={genderx}
            showGender={showGender}
            showStatus={showStatus}
            statusx={statusx}
            showMartial={showMartial}
            maritalx={maritalx}
            agex={agex}
            showAge={showAge}
            showAnniversary={showAnniversary}
            anniversaryx={anniversaryx}
            showNotes={showNotes}
            showFamily={showFamily}
            gradex={gradex}
            showGrade={showGrade}
            schoolx={schoolx}
            showSchool={showSchool}
            employerx={employerx}
            showEmployer={showEmployer}
            showWork={showWork}
            showMobile={showMobile}
            workPhonex={workPhonex}
            homePhonex={homePhonex}
            showHome={showHome}
            mobilePhonex={mobilePhonex}
            showFacebook={showFacebook}
            showTwitter={showTwitter}
            showInstagram={showInstagram}
            showLinkedin={showLinkedin}
            facebookx={facebookx}
            twitterx={twitterx}
            instagramx={instagramx}
            linkedinx={linkedinx}
          />
        </Col>
        <Col className="create-middle" sm={8}>
          <CreatePeopleMiddle
            genderx={genderx}
            statusx={statusx}
            maritalx={maritalx}
            agex={agex}
            notex={notex}
            familyx={familyx}
            anniversaryx={anniversaryx}
            schoolx={schoolx}
            employerx={employerx}
            gradex={gradex}
            workPhonex={workPhonex}
            homePhonex={homePhonex}
            mobilePhonex={mobilePhonex}
            facebookx={facebookx}
            twitterx={twitterx}
            instagramx={instagramx}
            linkedinx={linkedinx}
          />
        </Col>
        {/* <Col className="create-right" sm={3}>
          <CreatePeopleRight />
        </Col> */}
      </Container>
    </>
  );
};

export default CreatePeople;

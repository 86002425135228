import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert";
import "./giving.css";
import axios from "axios";
import Loading from "../../../components/Loading/Loading";
import EditOnlineGiving from "../../../components/OnlineGiving/EditOnlineGiving";
import ClearOnlineGiving from "../../../components/OnlineGiving/ClearOnlineGiving";
import AddCustomPayment from "../../../components/CustomPayment/AddCustomPayment";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import ManageCustomPayment from "../../../components/CustomPayment/ManageCustomPayment";

const OnlineGiving = () => {
  const {
    showAlert,
    logoutUser,
    isLoading,
    OnlineGiving,
    onlineData,
    getOnlineGiving,
    user,
  } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const getThisUser = async () => {
    try {
      const { data } = await authFetch.get(`/members/getthisuser/${user?._id}`);
      setRefreshedData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getThisUser();
  }, []);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [refreshedData, setRefreshedData] = useState(null);

  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);

  const [showAddCustom, setShowAddCustom] = useState(false);
  const handleShowAddCustom = () => setShowAddCustom(true);
  const handleShowAddCustomClose = () => setShowAddCustom(false);
  const [showManageCustom, setShowManageCustom] = useState(false);
  const handleShowManageCustom = () => setShowManageCustom(true);

  // useEffect(() => {
  //   if (refreshedData) {
  //     setUrl(refreshedData.skinsertId);
  //     setChurchName(refreshedData.churchName);
  //     setModifiedPk(refreshedData.pk);

  //     const removePattern = /sk_test_/g;
  //     const replacement = "23534534355";
  //     const skmodified = refreshedData.sk
  //       ? refreshedData.sk.replace(removePattern, replacement)
  //       : undefined;

  //     setModifiedSk(skmodified);
  //   }
  // }, [refreshedData]);

  const [actualURL, setActualURL] = useState("");
  const [pk, setPublicKey] = useState("");
  const [sk, setSecretKey] = useState(null);
  const [email, setEmail] = useState("");
  const [skData, setSkData] = useState(false);
  const [viewHandle, setViewHandle] = useState(false);
  const [url, setUrl] = useState("");
  const [churchName, setChurchName] = useState("");
  const [copiedShortedUrl, setCopiedShortedUrl] = useState(false);
  const [shortenedUrl, setShortenedUrl] = useState("");
  const [modifiedPk, setModifiedPk] = useState("");
  const [modifiedSk, setModifiedSk] = useState("");
  const baseURL = "http://192.168.1.177:3000/payment";
  //const baseURL = "https://staging.churchease.com/payment";
  // const baseURL = "https://churchease.com/payment";
  // const dynamicURLSegment = url; // Assuming 'url' is a variable holding some value
  // console.log(modifiedPk, modifiedSk);
  // const actualURL = `${baseURL}/${url}?churchName=${encodeURIComponent(
  //   churchName
  // )}&id=${encodeURIComponent(modifiedPk)}&ux=${encodeURIComponent(modifiedSk)}`;

  useEffect(() => {
    if (modifiedPk && modifiedSk) {
      const actualURL = `${baseURL}/${url}?churchName=${encodeURIComponent(
        churchName
      )}&id=${encodeURIComponent(modifiedPk)}&ux=${encodeURIComponent(
        modifiedSk
      )}`;
      console.log("Actual URL:", actualURL); // For debugging
      setActualURL(actualURL); // Example action after constructing the URL
    }
  }, [modifiedPk, modifiedSk, baseURL, url, churchName]);

  useEffect(() => {
    getOnlineGiving();
  }, []);

  // useEffect(() => {
  //   fetchShortenedUrl();
  // }, [refreshedData?.skinsertId, url]); // Dependency array to re-run the effect when 'url' changes

  useEffect(() => {
    dataRefresh();
  }, [onlineData]);

  const dataRefresh = () => {
    const skValue = onlineData?.onlinedata?.skinsertId;

    if (skValue === undefined) {
      // If skValue is truthy, it means there's some meaningful data
      setSecretKey("");
      setViewHandle(true);
    } else {
      // Handles undefined, null, and empty string cases
      setViewHandle(false);
      setSecretKey(skValue);
      setUrl(skValue);
    }
  };

  const handleCopy = () => {
    // setCopiedShortedUrl(true);
    // // Optional: Hide the prompt after a few seconds
    // setTimeout(() => setCopiedShortedUrl(false), 2000); // Hide after 2 seconds
    toast(
      <CustomToastContent
        title="Success"
        message="Link copied"
        type="success"
      />
    );
  };

  // const fetchShortenedUrl = async () => {
  //   const res = await axios.get(
  //     `https://tinyurl.com/api-create.php?url=${encodeURIComponent(actualURL)}`
  //   );
  //   setShortenedUrl(res.data);
  // };

  const handleChange = (e) => {
    setSecretKey(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!pk || !sk || !email) {
      toast(
        <CustomToastContent
          title="Error"
          message="Please fill in all fields"
          type="error"
        />
      );
      return;
    }

    // Prepare your donation data
    const donationData = {
      pk,
      sk,
      memail: email,
      type: "stripe",
      subscribers: [],
      churchName: churchName,
      churchId: refreshedData?._id,
    };

    // Make an HTTP PUT request to submit the donation data
    // const { data } = await authFetch.put(
    //   "/payment/online-giving",
    //   donationData
    // );

    OnlineGiving(donationData);
    getOnlineGiving();
    getThisUser();
    dataRefresh();
    setEmail("");
    setPublicKey("");
    setSecretKey("");
  };

  const handleEdit = () => {
    handleShow();
  };

  const handleClear = async () => {
    handleShow1();
  };

  const handleAddCustom = () => {
    handleShowAddCustom();
  };

  const handleManageCustom = () => {
    handleShowManageCustom();
  };
  const generatedLink = (
    <div className="flex justify-center flex-column" style={{ width: "55%" }}>
      {/* {copiedShortedUrl && (
        <div style={{ marginTop: "10px" }} className="inlinesuccess">
          Copied!
        </div>
      )} */}

      <div
        className="text-center text-xl text-bold mb-5"
        style={{ color: "var(--secondary-color)" }}
      >
        Online payment is now active and ready for use.
      </div>

      {/* <Form.Label htmlFor="inputPassword5">Link</Form.Label>
      <div className="flex flex-row gap-3 mb-3">
        <Form.Control
          type="text"
          value={actualURL}
          disabled
          //onChange={(e) => setWorkflow(e.target.value)}
        />

        <CopyToClipboard text={actualURL} onCopy={handleCopy}>
          <button className="jiluil">Copy</button>
        </CopyToClipboard>
      </div>
      <Form.Label className="mt-3" htmlFor="inputPassword5">
        Shortened Link
      </Form.Label>
      <div className="flex flex-row gap-3 mb-3">
        <Form.Control
          type="text"
          value={shortenedUrl}
          //onChange={(e) => setWorkflowDescription(e.target.value)}
          disabled
        />

        <CopyToClipboard text={shortenedUrl} onCopy={handleCopy}>
          <button className="jiluil3">Copy</button>
        </CopyToClipboard>
      </div> */}

      {/* <div className="mt-3 mb-5">
        {shortenedUrl && <QRCode value={shortenedUrl} />}
      </div> */}

      <div className="flex justify-center mt-3 mb-5 gap-3">
        <button className="jiluil" onClick={handleEdit}>
          Update Payment Details
        </button>
        <button className="jiluil3" onClick={handleClear}>
          Clear Payment Details
        </button>
      </div>
    </div>
  );

  return (
    <>
      <Container fluid>
        {showAlert && <Alert />}

        <Row className="headerbanner">
          <div className="bannerbig">Online Payment</div>
          <div className="bannersmall">
            Streamline your giving with our Online Payment platform, designed to
            simplify entering of payment details for contributions. Whether it's
            a single donation or ongoing support, manage your preferences
            seamlessly within our system.
          </div>
        </Row>

        <Row className="fundc2">
          <div className="flex justify-end gap-3">
            {refreshedData?.customPaymentData?.length > 0 && (
              <button onClick={handleManageCustom} className="jiluil">
                Manage Payment
              </button>
            )}
            <button onClick={handleAddCustom} className="jiluil">
              Custom Payment
            </button>
          </div>
        </Row>

        <Row className="fundc2" style={{ paddingTop: "3rem" }}>
          <Col>
            <div className="tyyuik">
              {viewHandle ? (
                <Form style={{ width: "50%" }}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="labfont">
                      Church Stripe Email
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="labfont">
                      Church Public Key
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Public Key"
                      value={pk}
                      onChange={(e) => setPublicKey(e.target.value)}
                      name="publicKey"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="labfont">
                      Church Secret Key
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter Secret Key"
                      value={sk || ""}
                      onChange={handleChange}
                      name="secretKey"
                    />
                  </Form.Group>

                  <Button className="wedweded mt-3" onClick={handleSubmit}>
                    {isLoading ? <Loading /> : "Submit"}
                  </Button>
                </Form>
              ) : (
                generatedLink
              )}
            </div>
          </Col>
          <ManageCustomPayment
            show={showManageCustom}
            setShow={setShowManageCustom}
            user={refreshedData}
          />
          <AddCustomPayment
            showx={showAddCustom}
            setShowx={setShowAddCustom}
            handleShowAddCustomClose={handleShowAddCustomClose}
            user={refreshedData}
          />

          <ClearOnlineGiving
            show1={show1}
            setShow1={setShow1}
            refreshedData={refreshedData}
          />
          <EditOnlineGiving
            show={show}
            setShow={setShow}
            refreshedData={refreshedData}
          />
        </Row>
      </Container>
    </>
  );
};

export default OnlineGiving;

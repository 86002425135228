import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useAppContext } from "../../../context/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Tooltip } from "antd";
import "../giving/giving.css";
import "./usermanagement.css";
import Alert from "../../../components/Alert/Alert";
import { toast } from "react-toastify";
import CustomToastContent from "../../../components/Toast/CustomToastContent";
import * as Sentry from "@sentry/react";

const UsersManagement = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const navigate = useNavigate();

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (member) => {
    setShow1(true);
    setSelectedEmail(member?.memail);
    setSelectedFirstName(member?.mfirstName);
    setSelectedLastName(member?.mlastName);
    setSelectedMemberType(member?.mstatus);
    //setSelectedRole(member?.role[0].roleName || "Unknown");
    //setSelectedRoleName(member?.role.roleName || selectedRoleName);
    setSelectedRoleNames(
      member?.role.length > 0 ? member.role.map((r) => r.roleName) : ["Unknown"]
    );

    setSelectedId(member?._id);
  };

  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [selectedRoleNames, setSelectedRoleNames] = useState([]);
  const [getUpdateRoleId, setGetUpdateRoleId] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedFirstName, setSelectedFirstName] = useState("");
  const [selectedLastName, setSelectedLastName] = useState("");
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedMemberType, setSelectedMemberType] = useState("member");

  const [fromDB, setFromDB] = useState([]);

  const {
    showAlert,
    getAllMembers,
    user,
    logoutUser,
    members,
    clearValues,
    updateMemberDetails,
    getPermission,
    permission,
  } = useAppContext();

  useEffect(() => {
    getAllMembers();
    getPermission();
  }, []);

  useEffect(() => {
    if (permission.length && permission) {
      setFromDB(permission);
    }
  }, [permission]);
  // console.log("fromDB", fromDB);
  useEffect(() => {
    if (getUpdateRoleId.length && getUpdateRoleId) {
      getUpdatePermission();
    }
  }, [getUpdateRoleId]);

  const getUpdatePermission = async () => {
    let { data } = await authFetch.get(`/permission`);
    setSelectedRole(data);
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    // if (
    //   !selectedEmail ||
    //   selectedRole.length === 0 ||
    //   !selectedFirstName ||
    //   !selectedLastName ||
    //   !getUpdateRoleId ||
    //   !selectedRole
    // ) {
    //   displayAlert();
    //   return;
    // }
    const role = selectedRoleNames.map((roleName) => ({ roleName }));

    const updateMember = {
      memail: selectedEmail,
      mfirstName: selectedFirstName,
      mlastName: selectedLastName,
      role: role,
      mstatus: selectedMemberType,
      // role: [{ roleName: getUpdateRoleId, selectedRole }],
      churchName: user.churchName,
      mId: selectedId,
    };

    updateMemberDetails({
      updateMember,
    });
    toast(
      <CustomToastContent
        title="Success"
        message="Role updated successfully."
        type="success"
      />
    );
    handleClose1();
    getAllMembers();
    window.scrollTo({ top: 0, behavior: "smooth" });
    clearValues();
  };

  const resendWelcome = async (e, member) => {
    e.preventDefault();

    try {
      const response = await authFetch.post(
        `/email/resendwelcome/${member._id}`
      );

      if (response.status === 200 || response.status === 201) {
        toast(
          <CustomToastContent
            title="Success"
            message="Notification sent successfully."
            type="success"
          />
        );

        getAllMembers();
      }
    } catch (error) {
      Sentry.captureException(error);
      toast(
        <CustomToastContent
          title="Error"
          message="An error occurred. Please try again."
          type="error"
        />
      );
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [modalRoles, setModalRoles] = useState([]);
  const [modalFullName, setModalFullName] = useState("");

  const handleShowModal = (member) => {
    setModalRoles(member.role);
    setModalFullName(`${member.mfirstName} ${member.mlastName}`);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <>
      <Container fluid>
        <Row className="headerbanner">
          <div className="bannerbig">Accesss Management</div>
          <div className="bannersmall">
            Utilize our Access Management feature to efficiently assign and
            customize member roles, enhancing organization and personalizing
            involvement within the community.
          </div>
        </Row>
        {/* <Row className="sssssssecwef">
          <div className="bnmjo" style={{ gap: "10px" }}>
            <button className="jiluil3" onClick={RolesManagement}>
              Manage Roles
            </button>
          </div>
        </Row> */}
        <Row>
          <div
            className="flex align-center justify-center flex-column w-100 pb-3"
            style={{ textAlign: "center" }}
          >
            <p className="qubulkf2">
              Check our{" "}
              <a
                href="https://churchease.com/knowledgebaseview/66706d7dc20672005705fda1"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "var(--secondary-color)",
                  textDecoration: "none",
                }}
              >
                knowledge base
              </a>{" "}
              learn more about access management and its use cases.
            </p>
          </div>
        </Row>

        <Row className="fundc2">
          <p
            style={{
              fontSize: "1em",
              paddingBottom: "15px",
              paddingTop: "15px",
            }}
          >
            <span
              style={{ fontWeight: "600", color: "var(--secondary-color)" }}
            >
              Note:
            </span>{" "}
            The default role for Member is Standard. This restricts members to
            only basic functionalities within the application. Members with the
            Standard role typically have limited access and permissions,
            allowing them to manage their personal information. To grant
            additional permissions, you can assign them to other roles.
          </p>
          {members.length > 0 ? (
            <Table hover style={{ backgroundColor: "#f7f7f7" }}>
              <thead>
                <tr style={{ fontSize: "100%" }}>
                  <th>Last Login</th>
                  <th>User Name</th>
                  <th>Member Status</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Update</th>
                </tr>
              </thead>

              <tbody>
                {members?.map((member) => (
                  <tr style={{ fontSize: "1em" }} key={member._id}>
                    <td>
                      {member.lastLogin
                        ? moment(member.lastLogin).format("LL, LT")
                        : "This user has not logged in yet."}
                    </td>
                    <td>
                      {member?.mfirstName} {member?.mlastName}
                    </td>
                    <td>
                      {member?.mstatus ? member.mstatus : "Add Member Status"}
                    </td>
                    <td>
                      {member?.role?.length > 0 ? (
                        <>
                          {member.role.slice(0, 5).map((r, index) => (
                            <div key={index}>{r.roleName}</div>
                          ))}
                          {member.role.length > 5 && (
                            <p
                              className="link"
                              onClick={() => handleShowModal(member)}
                              style={{
                                cursor: "pointer",
                                color: "var(--secondary-color)",
                                textDecoration: "underline",
                                fontWeight: "bold",
                              }}
                            >
                              See more
                            </p>
                          )}
                        </>
                      ) : (
                        "No Role"
                      )}
                    </td>
                    <td>
                      {member.isActive === true ? (
                        "Active"
                      ) : (
                        <div className="flex flex-row gap-3">
                          <p style={{ marginBottom: "0px" }}>
                            Account is not active
                          </p>
                          <button
                            className="jiluil"
                            onClick={(e) => resendWelcome(e, member)}
                          >
                            Resend Welcome Email
                          </button>
                        </div>
                      )}
                    </td>
                    <td>
                      <Tooltip title="Edit">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="dashicon"
                          onClick={() => handleShow1(member)}
                        />
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            "There is no assigned to this role."
          )}
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalFullName}'s Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalRoles.map((role, index) => (
            <p key={index}>{role.roleName}</p>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <div className="labelpad">
                <Form.Label htmlFor="inputPassword5" className="boldMember">
                  Email Address
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email Address"
                  value={selectedEmail}
                  onChange={(e) => setSelectedEmail(e.target.value)}
                  name="selectedEmail"
                />
              </div>

              <div className="labelpad">
                <Form.Label htmlFor="inputPassword5" className="boldMember">
                  First Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={selectedFirstName}
                  onChange={(e) => setSelectedFirstName(e.target.value)}
                  name="selectedFirstName"
                />
              </div>
              <div className="labelpad">
                <Form.Label htmlFor="inputPassword5" className="boldMember">
                  Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={selectedLastName}
                  onChange={(e) => setSelectedLastName(e.target.value)}
                  name="selectedLastName"
                />
              </div>

              <div className="labelpad">
                <Form.Label htmlFor="inputPassword5" className="boldMember">
                  Member Status
                </Form.Label>
                <Form.Select
                  value={selectedMemberType} // This should be an array of selected role names
                  onChange={(e) => setSelectedMemberType(e.target.value)}
                >
                  <option value="">
                    {user.mstatus ? user.status : "Select an Option"}
                  </option>

                  <option value="Member">Member</option>
                  <option value="Visitor">Visitor</option>
                  <option value="Leader">Leader</option>
                  <option value="Archived">No Longer Attends</option>
                  <option value="Worker">Worker</option>
                  <option value="Minister">Minister</option>
                  <option value="Pastor">Pastor</option>
                  <option value="Volunteer">Volunteer</option>
                </Form.Select>
              </div>

              {!selectedMemberType && (
                <p>Member status must be selected be roles can be assigned.</p>
              )}

              {selectedMemberType && (
                <div className="labelpad">
                  <Form.Label htmlFor="inputPassword5" className="boldMember">
                    Role
                  </Form.Label>
                  <Form.Select
                    multiple
                    value={selectedRoleNames} // This should be an array of selected role names
                    onChange={(e) => {
                      const selectedOptions = Array.from(
                        e.target.selectedOptions
                      ).map((option) => option.value);
                      setSelectedRoleNames(selectedOptions);
                    }}
                    style={{ height: "150px" }}
                  >
                    {fromDB.map((item) => (
                      <option key={item.roleId} value={item.roleName}>
                        {item.roleName}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="jiluil3" onClick={handleClose1}>
            Cancel
          </button>
          <button className="jiluil" onClick={handleSubmit1}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UsersManagement;

import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import "./sidebar.css";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import Loading from "../Loading/Loading";
import Logo3 from "../Logo/Logo3";
import Image from "react-bootstrap/Image";
import avatar from "./../../assets/images/blank2.png";

import {
  FaUserAlt,
  FaThList,
  FaCalendar,
  FaChartArea,
  FaPeopleArrows,
  FaPen,
  FaSave,
  FaMoneyBill,
  FaPray,
  FaPrayingHands,
  FaStream,
  FaAsterisk,
  FaNewspaper,
  FaNetworkWired,
  FaCloud,
  FaPiggyBank,
  FaAngleRight,
  FaAngleDown,
  FaBook,
  FaFont,
  FaRunning,
  FaPassport,
  FaFlagCheckered,
  FaRegFlag,
  FaPlaneSlash,
  FaCheck,
  FaEnvelope,
  FaPlusSquare,
  FaDochub,
  FaFileAudio,
  FaFileVideo,
  FaBuilding,
  FaMoneyCheck,
  FaMoneyBillWave,
  FaLevelUpAlt,
  FaFolderMinus,
  FaMinus,
  FaPlus,
  FaChartLine,
  FaChess,
  FaIcons,
  FaVideo,
  FaChartPie,
  FaTablets,
  FaTableTennis,
  FaMicrophone,
  FaSpeakerDeck,
  FaMapMarked,
  FaPatreon,
  FaBuffer,
  FaChild,
  FaClipboard,
  FaListUl,
  FaChalkboardTeacher,
  FaCalculator,
  FaAngellist,
  FaLightbulb,
  FaBible,
  FaQuestion,
} from "react-icons/fa";

const LatestSideBar = ({ onToggle, isMinimized }) => {
  const location = useLocation();
  const { logoutUser, user } = useAppContext();
  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [dashboard, setDashboard] = useState(false);
  const [people, setPeople] = useState(false);
  const [servicePlanner, setServicePlanner] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [tags, setTags] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [attendance, setAttendance] = useState(false);
  const [learning, setLearning] = useState(false);

  const [prayerWall, setPrayerWall] = useState(false);
  const [prayers, setPrayers] = useState(false);
  const [standard, setStandard] = useState(false);
  const [blog, setBlog] = useState(false);
  const [giving, setGiving] = useState(false);
  const [chatbot, setChatbot] = useState(false);
  const [savedView, setSavedView] = useState(false);
  const [myView, setMyView] = useState(true);
  const [form, setForm] = useState(false);
  const [showStandardForm, setShowStandardForm] = useState(false);
  const [showFinance, setShowFinance] = useState(false);
  const [showAccounting, setShowAccounting] = useState(false);
  const [showFollowup, setShowFollowup] = useState(false);
  const [showVolunteer, setShowVolunteer] = useState(false);
  const [showLearning, setShowLearning] = useState(false);
  const [showChildren, setShowChildren] = useState(false);
  const [showBlog, setShowBlog] = useState(false);
  const [showStandardVolunteer, setShowStandardVolunteer] = useState(false);
  const [followup, setFollowup] = useState(false);
  const [volunteer, setVolunteer] = useState(false);
  const [showStandardLearning, setShowStandardLearning] = useState(false);
  const [showStandardGiving, setShowStandardGiving] = useState(false);
  const [showMessaging, setShowMessaging] = useState(false);
  const [messaging, setMessaging] = useState(false);
  const [churchAccount, setChurchAccount] = useState({});
  const [unknown, setUnknown] = useState(false);
  const [unknownName, setUnknownName] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [isAppAdmin, setIsAppAdmin] = useState(false);
  const [showFellowship, setShowFellowship] = useState(false);

  useEffect(() => {
    if (
      churchAccount?.memberShip === "Free" &&
      churchAccount?.freeAccountStatus === "active"
    ) {
      setIsFree(true);
    } else {
      setIsFree(false);
    }
  }, [churchAccount]);

  const [permissions, setPermissions] = useState({
    canPeople: false,
    canLearning: false,
    canFollowUp: false,
    canFellowship: false,
    canGiving: false,
    canMinistry: false,
    canGroups: false,
    canServicePlanner: false,
    canLiveStreaming: false,
    canChildren: false,
    canForm: false,
    canEvents: false,
    canDashboard: true,
    canView: true,
    canAccounting: false,
    // Add more permissions as needed
  });

  const [appAdmin, setAppAdmin] = useState(false);

  useEffect(() => {
    if (!user || !user.role) return;

    const relevantRoles = [
      "Church Giving",
      "Church Accounting",
      "Follow Up",
      "Learning Center",
      "People",
      "Fellowship",
      "Ministry",
      "Groups",
      "Service Planner",
      "Forms",
      "Events",
      "Live Streaming",
      "Children",
    ];

    // Extract roleName values from user.role array
    const roleNames = user.role.map((role) => role.roleName);
    const isSuperAdmin = roleNames.includes("Super Admin");
    const isAdmin = roleNames.includes("Admin");
    const appAdmin = roleNames.includes("App Admin");
    const freeUser =
      isFree && roleNames.some((role) => relevantRoles.includes(role));

    if (appAdmin) {
      setAppAdmin(true);
    }

    setIsAppAdmin(isAdmin);

    // Dynamically update permissions based on roleNames and isFree
    const updatedPermissions = {
      canFollowUp:
        isSuperAdmin || freeUser || isAdmin || roleNames.includes("Follow Up"),
      canLearning:
        isSuperAdmin ||
        isAdmin ||
        freeUser ||
        roleNames.includes("Learning Center"),
      canPeople:
        isSuperAdmin || isAdmin || freeUser || roleNames.includes("People"),
      canFellowship:
        isSuperAdmin || isAdmin || freeUser || roleNames.includes("Fellowship"),
      canGiving:
        isSuperAdmin ||
        freeUser ||
        isAdmin ||
        roleNames.includes("Church Giving"),
      canMinistry:
        isSuperAdmin || freeUser || isAdmin || roleNames.includes("Ministry"),
      canGroups:
        isSuperAdmin || freeUser || isAdmin || roleNames.includes("Groups"),
      canServicePlanner:
        isSuperAdmin ||
        isAdmin ||
        freeUser ||
        roleNames.includes("Service Planner"),
      canForm:
        isSuperAdmin || freeUser || isAdmin || roleNames.includes("Forms"),
      canEvents:
        isSuperAdmin || freeUser || isAdmin || roleNames.includes("Events"),
      canLiveStreaming:
        isSuperAdmin ||
        isAdmin ||
        freeUser ||
        roleNames.includes("Live Streaming"),
      canChildren:
        isSuperAdmin || freeUser || isAdmin || roleNames.includes("Children"),
      canAccounting:
        isSuperAdmin ||
        freeUser ||
        isAdmin ||
        roleNames.includes("Church Accounting"),
      canDashboard:
        isSuperAdmin ||
        isAdmin ||
        freeUser ||
        ["Worker", "Leader", "Minister", "Pastor", "Volunteer"].includes(
          user.memberShip
        ),
      canView: isSuperAdmin || isAdmin || freeUser,
      // Add or update more permissions as needed
    };

    setPermissions(updatedPermissions);
  }, [user, isFree]);

  useEffect(() => {
    if (
      user?.role[0].roleName?.includes("Standard") &&
      user.mstatus === "Member"
    ) {
      setStandard(true);
    }
  }, [user.role]);

  useEffect(() => {
    if (user?.role[0].roleName?.includes("App Admin")) {
      setAppAdmin(true);
    }
  }, [user.role]);

  useEffect(() => {
    const fetchChurchSubscriptionStatus = async () => {
      // Skip the fetching logic if the user is currently on the payment page
      if (location.pathname === "/auth/paymentpage") {
        return;
      }

      try {
        // Fetching the church account to get the subscription status
        const churchdomain = user?.churchDomain;
        const response = await authFetch.get(
          `/members/churchsub/${churchdomain}`
        );
        const churchAccountData = response.data;

        setChurchAccount(churchAccountData); // Debugging line

        // Assuming the church account's subscription data is structured similarly to the previous user subscription data
        const hasActiveSubscription = churchAccountData?.subscription?.every(
          (sub) => sub.status.toLowerCase() !== "canceled"
        );

        if (!hasActiveSubscription) {
          // Redirecting to the pricing list if there's no active subscription
          navigate("/auth/paymentpage");
        } else {
          // Setting the view or state based on the active subscription
          setMyView(true);
        }
      } catch (error) {
        console.error("Error fetching church subscription status:", error);
      }
    };

    fetchChurchSubscriptionStatus();
  }, [navigate, location.pathname]);

  const toggleLearning = () => {
    setShowLearning(!showLearning);
    setShowFollowup(false);
    setShowVolunteer(false);
    setShowMessaging(false);
    setShowFinance(false);
    setShowAccounting(false);
    setShowStandardForm(false);
    setShowChildren(false);
  };

  const toggleFellowship = () => {
    setShowFellowship(!showFellowship);
    setShowFollowup(false);
    setShowVolunteer(false);
    setShowMessaging(false);
    setShowLearning(false);
    setShowAccounting(false);
    setShowStandardForm(false);
  };

  const toggleChildren = () => {
    setShowChildren(!showChildren);
    setShowFollowup(false);
    setShowVolunteer(false);
    setShowMessaging(false);
    setShowLearning(false);
    setShowAccounting(false);
    setShowStandardForm(false);
  };

  const toggleBlog = () => {
    setShowBlog(!showBlog);
  };

  const toggleFinance = () => {
    setShowFinance(!showFinance);
    setShowFollowup(false);
    setShowVolunteer(false);
    setShowMessaging(false);
    setShowLearning(false);
    setShowAccounting(false);
    setShowStandardForm(false);
  };

  const toggleFollowup = () => {
    setShowFollowup(!showFollowup);
    setShowFinance(false);
    setShowVolunteer(false);
    setShowMessaging(false);
    setShowLearning(false);
    setShowStandardForm(false);
    setShowAccounting(false);
  };

  const toggleVolunteer = () => {
    setShowVolunteer(!showVolunteer);
    setShowFinance(false);
    setShowFollowup(false);
    setShowMessaging(false);
    setShowLearning(false);
    setShowStandardForm(false);
    setShowAccounting(false);
  };

  const toggleForm = () => {
    setShowStandardForm(!showStandardForm);
    setShowFinance(false);
    setShowFollowup(false);
    setShowMessaging(false);
    setShowLearning(false);
    setShowVolunteer(false);
    setShowAccounting(false);
  };

  const toggleStandardVolunteer = () => {
    setShowStandardVolunteer(!showStandardVolunteer);
    setShowStandardLearning(false);
    setShowStandardGiving(false);
    setShowStandardForm(false);
  };

  const toggleStandardLearning = () => {
    setShowStandardLearning(!showStandardLearning);
    setShowStandardVolunteer(false);
    setShowStandardGiving(false);
    setShowStandardForm(false);
  };

  const toggleStandardGiving = () => {
    setShowStandardGiving(!showStandardGiving);
    setShowStandardVolunteer(false);
    setShowStandardLearning(false);
    setShowStandardForm(false);
  };

  const toggleMessaging = () => {
    setShowMessaging(!showMessaging);
    setShowVolunteer(false);
    setShowFollowup(false);
    setShowFinance(false);
    setShowLearning(false);
    setShowStandardGiving(false);
    setShowStandardForm(false);
    setShowAccounting(false);
  };

  const toggleChurchAccount = () => {
    setShowAccounting(!showAccounting);
    setShowVolunteer(false);
    setShowFollowup(false);
    setShowFinance(false);
    setShowLearning(false);
    setShowStandardGiving(false);
    setShowStandardForm(false);
  };
  const [myChurchLogo, setMyChurchLogo] = useState(null);
  useEffect(() => {
    if (user) {
      churchLogo();
    }
  }, [user]);

  const churchLogo = async (e) => {
    try {
      const response = await authFetch.get(`/auth/churchlogo`);

      if (response.status === 200 || response.status === 201) {
        setMyChurchLogo(response.data.churchSettings);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Nav className="flex-column sidebar">
      <div
        className="flex items-center w-100"
        style={{
          paddingTop: "1rem",
          justifyContent: isMinimized ? "center" : "flex-end",
          paddingRight: isMinimized ? "0" : "1rem", // Add padding to the right when not minimized
          cursor: "pointer",
        }}
      >
        {isMinimized ? (
          <FaPlus onClick={onToggle} style={{ fontSize: "1.3em" }} />
        ) : (
          <FaMinus onClick={onToggle} style={{ fontSize: "1.3em" }} />
        )}
      </div>
      {/* Clicking this will call the toggle function passed as a prop */}
      <Navbar.Brand
        className="ewfws"
        onClick={() => navigate("/auth/dashboard")}
        style={{ display: isMinimized ? "none" : "flex" }} // Conditionally setting the display property
      >
        <div className="flex flex-column gap-3 justify-center items-center">
          {user && (
            <Image
              width={90}
              height={90}
              src={myChurchLogo?.Location ? myChurchLogo.Location : avatar}
              roundedCircle
            />
          )}
          <p
            style={{
              fontSize: "1em",
              fontWeight: "600",
              marginBottom: "0px",
              textWrap: "pretty",
            }}
          >
            {user.churchName}
          </p>
        </div>
      </Navbar.Brand>
      <hr />
      {permissions.canDashboard && (
        <>
          <NavLink
            to="/auth/dashboard"
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaPeopleArrows className="nmdd" />
              Dashboard
            </div>
          </NavLink>
          <NavLink
            to="/auth/announcement"
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaMicrophone className="nmdd" />
              Announcements
            </div>
          </NavLink>
        </>
      )}
      {standard && myView && (
        <>
          <NavLink
            to={"/auth/standardhome/"}
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaChartArea style={{ color: "white" }} />
              Dashboard
            </div>
          </NavLink>

          <NavLink
            to={"/auth/standardannouncements/"}
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaQuestion style={{ color: "white" }} />
              What's Happening?
            </div>
          </NavLink>

          <NavLink
            to={"/auth/peopleview/" + user._id}
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaUserAlt style={{ color: "white" }} />
              Member Profile
            </div>
          </NavLink>

          <NavLink
            to={"/auth/standardcheckin"}
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaFlagCheckered style={{ color: "white" }} />
              Family Check In
            </div>
          </NavLink>

          <NavLink
            to="/auth/myfollowup"
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaRunning style={{ color: "white" }} />
              My Follow-Up
            </div>
          </NavLink>
        </>
      )}
      {(permissions.canPeople || admin || superAdmin) && myView && (
        <NavLink
          to="/auth/savedview"
          className={({ isActive }) =>
            isActive ? "sideder linktoicon" : "linktoicon"
          }
        >
          <div className="wefwe">
            <FaSave style={{ color: "white" }} />
            Saved View
          </div>
        </NavLink>
      )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("People") &&
        (permissions.canPeople || admin || superAdmin) &&
        myView && (
          <NavLink
            to="/auth/people"
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaUserAlt style={{ color: "white" }} />
              People
            </div>
          </NavLink>
        )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Ministry") &&
        (permissions.canMinistry || admin || superAdmin) &&
        myView && (
          <NavLink
            to="/auth/tags"
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaBible className="nmdd" />
              Ministry
            </div>
          </NavLink>
        )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Groups") &&
        (tags || permissions.canGroups || admin || superAdmin) &&
        myView && (
          <NavLink
            to="/auth/container"
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaPeopleArrows className="nmdd" />
              Groups
            </div>
          </NavLink>
        )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Service Planner") &&
        (permissions.canServicePlanner || admin || superAdmin) &&
        myView && (
          <NavLink
            to="/auth/serviceplanner"
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaMapMarked className="nmdd" />
              Service Planner
            </div>
          </NavLink>
        )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Forms") &&
        (volunteer || permissions.canForm || admin || superAdmin) &&
        myView && (
          <NavLink
            to="/auth/forms"
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaFont style={{ color: "white" }} />
              Forms
            </div>
          </NavLink>
        )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Live Streaming") &&
        (permissions.canLiveStreaming || admin || superAdmin) &&
        myView && (
          <NavLink
            to="/auth/livestreaming"
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaVideo style={{ color: "white" }} />
              Live Streaming
            </div>
          </NavLink>
        )}
      {appAdmin && (
        <>
          <NavLink
            to={"/auth/appdashboard"}
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaChartArea className="nmdd" />
              Admin Dashboard
            </div>
          </NavLink>

          <NavLink
            to={"/auth/permissions"}
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaChartArea className="nmdd" />
              Add Permission
            </div>
          </NavLink>

          <NavLink
            to={"/auth/addentitlementmanually"}
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaChartArea className="nmdd" />
              Add Entitlement Manually
            </div>
          </NavLink>

          <NavLink
            to={"/auth/knowledgebase"}
            className={({ isActive }) =>
              isActive ? "sideder linktoicon" : "linktoicon"
            }
          >
            <div className="wefwe">
              <FaChartArea className="nmdd" />
              Knowledge Base
            </div>
          </NavLink>

          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showBlog ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleBlog}>
              <FaPatreon style={{ color: "white" }} />
              Blog {showBlog ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>

          {showBlog && (
            <>
              {/* <NavLink
                to={"/auth/appdashboard/"}
                className={({ isActive }) =>
                  isActive ? "sideder linktoicon" : "linktoicon"
                }
              >
                <div className="wefwe">
                  <FaChartArea style={{ color: "white" }} />
                  Dashboard
                </div>
              </NavLink> */}

              <NavLink
                to="/auth/blogdashboard"
                activeClassName="sideder"
                className="linktoicon"
              >
                <div className="wefwe">
                  <FaBuilding style={{ color: "white" }} />
                  Blog Dashboard
                </div>
              </NavLink>

              <NavLink
                to="/auth/blogmain"
                activeClassName="sideder"
                className="linktoicon"
              >
                <div className="wefwe">
                  <FaFileVideo style={{ color: "white" }} />
                  Add New Post
                </div>
              </NavLink>
            </>
          )}
        </>
      )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Fellowship") &&
        (permissions.canFellowship || admin || superAdmin) &&
        myView && (
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showFellowship ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleFellowship}>
              <FaPen style={{ color: "white" }} />
              Fellowship {showFellowship ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>
        )}
      {showFellowship && (
        <>
          <NavLink
            to="/auth/prayers"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaBuilding style={{ color: "white" }} />
              Prayers
            </div>
          </NavLink>

          <NavLink
            to="/auth/prayerwall"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaFileVideo style={{ color: "white" }} />
              Prayer Wall
            </div>
          </NavLink>

          <NavLink
            to="/auth/testimony"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaSpeakerDeck style={{ color: "white" }} />
              Testimonies
            </div>
          </NavLink>

          <NavLink
            to="/auth/testimonywall"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaTableTennis style={{ color: "white" }} />
              Testimony Wall
            </div>
          </NavLink>

          <NavLink
            to="/auth/fellowshipsettings"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaNetworkWired style={{ color: "white" }} />
              Settings
            </div>
          </NavLink>
        </>
      )}

      {standard && myView && (
        <div
          to="#"
          className="linktoicon"
          style={{
            backgroundColor: showFellowship ? "#B1974E" : "",
            borderTop: "1px solid #fff",
            cursor: "pointer",
          }}
        >
          <div className="wefwe" onClick={toggleFellowship}>
            <FaPen style={{ color: "white" }} />
            Fellowship {showFellowship ? <FaAngleDown /> : <FaAngleRight />}
          </div>
        </div>
      )}
      {/* {showFellowship && (
        <>
          <NavLink
            to="/auth/prayers"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaBuilding style={{ color: "white" }} />
              Prayers
            </div>
          </NavLink>

          <NavLink
            to="/auth/testimony"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaSpeakerDeck style={{ color: "white" }} />
              Testimonies
            </div>
          </NavLink>
        </>
      )} */}

      {standard && myView && (
        <>
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showStandardForm ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleForm}>
              <FaFont style={{ color: "white" }} />
              Forms
              {showStandardForm ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>

          {showStandardForm && (
            <>
              <NavLink
                to="/auth/standardform"
                className={({ isActive }) =>
                  isActive ? "sideder linktoicon" : "linktoicon"
                }
              >
                <div className="wefwe">
                  <FaPen style={{ color: "white" }} />
                  My Form
                </div>
              </NavLink>

              <NavLink
                to="/auth/mylearning"
                className={({ isActive }) =>
                  isActive ? "sideder linktoicon" : "linktoicon"
                }
              >
                <div className="wefwe">
                  <FaBook style={{ color: "white" }} />
                  All Forms
                </div>
              </NavLink>
            </>
          )}
        </>
      )}
      {standard && myView && (
        <>
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showStandardVolunteer ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleStandardVolunteer}>
              <FaPassport style={{ color: "white" }} />
              Volunteer Center
              {showStandardVolunteer ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>

          {showStandardVolunteer && (
            <>
              <NavLink
                to="/auth/volunteerhome"
                className={({ isActive }) =>
                  isActive ? "sideder linktoicon" : "linktoicon"
                }
              >
                <div className="wefwe">
                  <FaBuilding style={{ color: "white" }} />
                  Volunteer Center
                </div>
              </NavLink>

              <NavLink
                to="/auth/myvolunteerapp"
                activeClassName="sideder"
                className="linktoicon"
              >
                <div className="wefwe">
                  <FaCheck style={{ color: "white" }} />
                  My Expressions
                </div>
              </NavLink>
            </>
          )}
        </>
      )}
      {standard && myView && (
        <>
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showStandardLearning ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleStandardLearning}>
              <FaEnvelope style={{ color: "white" }} />
              Learning Center
              {showStandardLearning ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>

          {showStandardLearning && (
            <>
              <NavLink
                to="/auth/learningmain"
                className={({ isActive }) =>
                  isActive ? "sideder linktoicon" : "linktoicon"
                }
              >
                <div className="wefwe">
                  <FaPen style={{ color: "white" }} />
                  Learning Center
                </div>
              </NavLink>

              <NavLink
                to="/auth/mylearning"
                className={({ isActive }) =>
                  isActive ? "sideder linktoicon" : "linktoicon"
                }
              >
                <div className="wefwe">
                  <FaBook style={{ color: "white" }} />
                  My Learning
                </div>
              </NavLink>

              <NavLink
                to="/auth/growthplan"
                className={({ isActive }) =>
                  isActive ? "sideder linktoicon" : "linktoicon"
                }
              >
                <div className="wefwe">
                  <FaChartLine style={{ color: "white" }} />
                  My Growth Plan
                </div>
              </NavLink>
            </>
          )}
        </>
      )}
      {standard && myView && (
        <>
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showStandardGiving ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleStandardGiving}>
              <FaMoneyBill style={{ color: "white" }} />
              Givings
              {showStandardGiving ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>

          {showStandardGiving && (
            <>
              <NavLink
                to="/auth/mygiving"
                className={({ isActive }) =>
                  isActive ? "sideder linktoicon" : "linktoicon"
                }
              >
                <div className="wefwe">
                  <FaPen style={{ color: "white" }} />
                  Give
                </div>
              </NavLink>

              <NavLink
                to="/auth/standardgiving"
                className={({ isActive }) =>
                  isActive ? "sideder linktoicon" : "linktoicon"
                }
              >
                <div className="wefwe">
                  <FaIcons style={{ color: "white" }} />
                  My Givings
                </div>
              </NavLink>

              <NavLink
                to="/auth/customgiving"
                className={({ isActive }) =>
                  isActive ? "sideder linktoicon" : "linktoicon"
                }
              >
                <div className="wefwe">
                  <FaChess style={{ color: "white" }} />
                  Custom Giving
                </div>
              </NavLink>
            </>
          )}
        </>
      )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Learning Center") &&
        (permissions.canLearning || admin || superAdmin) &&
        myView && (
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showLearning ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleLearning}>
              <FaPen style={{ color: "white" }} />
              Learning Center
              {showLearning ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>
        )}
      {showLearning && (
        <>
          <NavLink
            to="/auth/devotional"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaChalkboardTeacher style={{ color: "white" }} />
              Devotional
            </div>
          </NavLink>

          <NavLink
            to="/auth/digitallibrary"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaBuilding style={{ color: "white" }} />
              Digital Library
            </div>
          </NavLink>

          <NavLink
            to="/auth/learninghome"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaFileVideo style={{ color: "white" }} />
              Video Content
            </div>
          </NavLink>

          <NavLink
            to="/auth/audiodashboard"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaFileAudio style={{ color: "white" }} />
              Audio Content
            </div>
          </NavLink>

          <NavLink
            to="/auth/documentdashboard"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaDochub style={{ color: "white" }} />
              Document Content
            </div>
          </NavLink>

          <NavLink
            to="/auth/developmentplan"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaPlusSquare style={{ color: "white" }} />
              Growth Planner
            </div>
          </NavLink>
        </>
      )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Church Giving") &&
        (permissions.canGiving || admin || superAdmin) &&
        myView && (
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showFinance ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleFinance}>
              <FaMoneyBill style={{ color: "white" }} />
              Church Giving
              {showFinance ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>
        )}
      {showFinance && (
        <>
          <NavLink
            to="/auth/givinghome"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaMoneyBill style={{ color: "white" }} />
              Add Giving
            </div>
          </NavLink>

          <NavLink
            to="/auth/batches"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaStream style={{ color: "white" }} />
              Giving Batch
            </div>
          </NavLink>

          <NavLink
            to="/auth/addfunds"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaAsterisk style={{ color: "white" }} />
              Giving Category
            </div>
          </NavLink>

          <NavLink
            to="/auth/methods"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaNetworkWired style={{ color: "white" }} />
              Giving Method
            </div>
          </NavLink>

          <NavLink
            to="/auth/reports"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaNewspaper style={{ color: "white" }} />
              Giving Report
            </div>
          </NavLink>

          <NavLink
            to="/auth/online"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaCloud style={{ color: "white" }} />
              Giving Online
            </div>
          </NavLink>

          <NavLink
            to="/auth/onlinetransactions"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaMoneyCheck style={{ color: "white" }} />
              Web Transactions
            </div>
          </NavLink>

          <NavLink
            to="/auth/statements"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaPiggyBank style={{ color: "white" }} />
              Giving Statement
            </div>
          </NavLink>
        </>
      )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Church Accounting") &&
        (permissions.canAccounting || admin || superAdmin) &&
        myView && (
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showAccounting ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleChurchAccount}>
              <FaCalculator style={{ color: "white" }} />
              Church Accounting
              {showAccounting ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>
        )}
      {showAccounting && (
        <>
          <NavLink
            to="/auth/expenses"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaMoneyBillWave style={{ color: "white" }} />
              Expenses
            </div>
          </NavLink>

          <NavLink
            to="/auth/addexpense"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaMoneyBillWave style={{ color: "white" }} />
              Add Expense
            </div>
          </NavLink>

          <NavLink
            to="/auth/budget"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaNetworkWired style={{ color: "white" }} />
              Budget
            </div>
          </NavLink>
          <NavLink
            to="/auth/accountstatement"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaLevelUpAlt style={{ color: "white" }} />
              Account Statement
            </div>
          </NavLink>
        </>
      )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Follow Up") &&
        (permissions.canFollowUp || admin || superAdmin) &&
        myView && (
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showFollowup ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleFollowup}>
              <FaRunning style={{ color: "white" }} />
              Follow-Up {showFollowup ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>
        )}
      {showFollowup && (
        <>
          <NavLink
            to="/auth/myfollowup"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaMoneyBill style={{ color: "white" }} />
              My Follow-Ups
            </div>
          </NavLink>

          <NavLink
            to="/auth/assignedfollowup"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaStream style={{ color: "white" }} />
              Other Follow-Ups
            </div>
          </NavLink>

          <NavLink
            to="/auth/allfollowup"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaAsterisk style={{ color: "white" }} />
              All Follow-Ups
            </div>
          </NavLink>

          <NavLink
            to="/auth/followupsettings"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaNetworkWired style={{ color: "white" }} />
              Settings
            </div>
          </NavLink>
        </>
      )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Children") &&
        (permissions.canChildren || admin || superAdmin) &&
        myView && (
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showChildren ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleChildren}>
              <FaChild style={{ color: "white" }} />
              Children {showChildren ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>
        )}
      {showChildren && (
        <>
          <NavLink
            to="/auth/classroom"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaClipboard style={{ color: "white" }} />
              Classroom
            </div>
          </NavLink>

          <NavLink
            to="/auth/childrenlist"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaListUl style={{ color: "white" }} />
              Children List
            </div>
          </NavLink>

          <NavLink
            to="/auth/teachers"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaChalkboardTeacher style={{ color: "white" }} />
              Teachers
            </div>
          </NavLink>

          <NavLink
            to="/auth/childrenlibrary"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaLightbulb style={{ color: "white" }} />
              Children Library
            </div>
          </NavLink>
        </>
      )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Events") &&
        (permissions.canEvents || admin || superAdmin) &&
        myView && (
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showVolunteer ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleVolunteer}>
              <FaCalendar style={{ color: "white" }} />
              Events {showVolunteer ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>
        )}
      {showVolunteer && (
        <>
          <NavLink
            to="/auth/calendar"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaCalendar style={{ color: "white" }} />
              New Event
            </div>
          </NavLink>

          <NavLink
            to="/auth/attendance"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaThList style={{ color: "white" }} />
              Attendance
            </div>
          </NavLink>

          <NavLink
            to="/auth/volunteermanagement"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaPassport style={{ color: "white" }} />
              Volunteer Centre
            </div>
          </NavLink>

          <NavLink
            to="/auth/checkin"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaFlagCheckered style={{ color: "white" }} />
              Family Check In
            </div>
          </NavLink>

          <NavLink
            to="/auth/checkout"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaRegFlag style={{ color: "white" }} />
              Check Out
            </div>
          </NavLink>
        </>
      )}
      {churchAccount &&
        Array.isArray(churchAccount.subscription) &&
        churchAccount.entitlement.includes("Communication") &&
        (volunteer || admin || superAdmin) &&
        myView && (
          <div
            to="#"
            className="linktoicon"
            style={{
              backgroundColor: showMessaging ? "#B1974E" : "",
              borderTop: "1px solid #fff",
              cursor: "pointer",
            }}
          >
            <div className="wefwe" onClick={toggleMessaging}>
              <FaChartPie style={{ color: "white" }} />
              Messaging {showMessaging ? <FaAngleDown /> : <FaAngleRight />}
            </div>
          </div>
        )}
      {showMessaging && (
        <>
          <NavLink
            to="/auth/inbox"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaCalendar style={{ color: "white" }} />
              Inbox
            </div>
          </NavLink>

          <NavLink
            to="/auth/outbox"
            activeClassName="sideder"
            className="linktoicon"
          >
            <div className="wefwe">
              <FaThList style={{ color: "white" }} />
              Outbox
            </div>
          </NavLink>
        </>
      )}
    </Nav>
  );
};

export default LatestSideBar;

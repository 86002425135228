import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { logEvent } from "../../analytics";
import sss from "../../assets/images/sss.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./signup.css";
import Form from "react-bootstrap/Form";
import { useNavigate, Link } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import Loading from "../../components/Loading/Loading";
import Alert from "../../components/Alert/Alert";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import CustomToastContent from "../../components/Toast/CustomToastContent";

const SignUpPageTrial = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [churchName, setChurchName] = useState("");
  const [churchDomain, setChurchDomain] = useState("");
  const [error, setError] = useState("");
  const { user, alertText, registerMemberTrial, displayAlert, showAlert } =
    useAppContext();
  const [isMember, setIsMember] = useState(true);
  const [errorMain, setErrorMain] = useState("");

  // const handleProcess = async (e) => {
  //   e.preventDefault();

  //   logEvent(
  //     "Free Trial",
  //     "Clicked",
  //     "Get Started - Free Trial Button Clicked",
  //     1
  //   );

  //   if (
  //     firstName === "" ||
  //     lastName === "" ||
  //     email === "" ||
  //     password === "" ||
  //     confirmPassword === "" ||
  //     churchName === "" ||
  //     churchDomain === ""
  //   ) {
  //     toast(
  //       <CustomToastContent
  //         title="Error"
  //         message="All fields are required"
  //         type="error"
  //       />
  //     );
  //     return; // Exit the function if validation fails
  //   }

  //   if (password !== confirmPassword) {
  //     toast(
  //       <CustomToastContent
  //         title="Error"
  //         message="Passwords do not match"
  //         type="error"
  //       />
  //     );
  //     return; // Exit the function if passwords do not match
  //   }

  //   const currentUser = {
  //     email: email,
  //     password: password,
  //     confirmPassword: confirmPassword,
  //     mfirstName: firstName,
  //     mlastName: lastName,
  //     churchName: churchName,
  //     churchDomainx: churchDomain,
  //     role: [{ roleName: "Super Admin" }],
  //     memberShip: "Free",
  //     mstatus: "Worker",
  //   };

  //   if (isMember) {
  //     try {
  //       const response = await registerMemberTrial({
  //         currentUser,
  //         endPoint: "registertrial",
  //         alertText: "Registration Successful! Redirecting...",
  //       });
  //       console.log(response);
  //       // Handle success response (e.g., redirect the user, show a success message)
  //     } catch (error) {
  //       console.error(error);
  //       toast(
  //         <CustomToastContent
  //           title="Error"
  //           message="Registration failed. Please try again."
  //           type="error"
  //         />
  //       );
  //     }
  //   }
  // };

  const handleProcess = async (e) => {
    e.preventDefault();

    logEvent(
      "Free Trial",
      "Clicked",
      "Get Started - Free Trial Button Clicked",
      1
    );

    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      churchName === "" ||
      churchDomain === ""
    ) {
      toast(
        <CustomToastContent
          title="Error"
          message="All fields are required"
          type="error"
        />
      );
      return; // Exit the function if validation fails
    }

    if (password !== confirmPassword) {
      toast(
        <CustomToastContent
          title="Error"
          message="Passwords do not match"
          type="error"
        />
      );
      return; // Exit the function if passwords do not match
    }

    const currentUser = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      mfirstName: firstName,
      mlastName: lastName,
      churchName: churchName,
      churchDomainx: churchDomain,
      role: [{ roleName: "Super Admin" }],
      memberShip: "Free",
      mstatus: "Worker",
    };

    if (isMember) {
      try {
        const response = await registerMemberTrial({
          currentUser,
          endPoint: "registertrial",
          alertText: "Registration Successful! Redirecting...",
        });

        if (response && (response.status === 200 || response.status === 201)) {
          // Handle success response (e.g., redirect the user, show a success message)
          console.log(response.data);
          toast(
            <CustomToastContent
              title="Success"
              message="Registration successful!"
              type="success"
            />
          );
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.message ||
          "Registration failed. Please try again.";
        toast(
          <CustomToastContent
            title="Error"
            message={errorMessage}
            type="error"
          />
        );
      }
    }
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] =
    useState(false);
  const togglePasswordConfirmVisibility = () => {
    setIsPasswordConfirmVisible(!isPasswordConfirmVisible);
  };

  useEffect(() => {
    let timeoutId;

    if (user) {
      setIsLoading(true); // Start loading

      timeoutId = setTimeout(() => {
        setIsLoading(false); // Stop loading
        navigate("/auth/dashboard");
      }, 3000);
    }

    // Cleanup function
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        setIsLoading(false); // Ensure loading is stopped if the component unmounts
      }
    };
  }, [user, navigate]);

  // Render the Loading component while loading
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container fluid style={{ margin: "0", padding: "0" }}>
      {/* {showAlert && <Alert />} */}
      <Row className="container2">
        <Col sm={7} md={7} className="logindiv2xx">
          <Image fluid src={sss} className="imgsizexs" />
          <p
            style={{
              color: "#fff",
              fontWeight: "bold",
              paddingTop: "2rem",
              fontSize: "1.9rem",
              width: "65%",
            }}
          >
            Transform Your Church Management with Churchease
          </p>
          <p className="redi">
            <Link to="/" className="single5">
              <FontAwesomeIcon icon={faArrowLeft} className="single1" /> Back to
              home page
            </Link>
          </p>
        </Col>
        <Col className="signupcolumn">
          <p
            className="flex justify-center "
            style={{ fontSize: "1.5em", fontWeight: "bold", color: "#FE7064" }}
          >
            Start your 90-days free trial
          </p>

          <Form className="sufsh">
            {/* {showAlert && <Alert />} */}
            <Form.Group controlId="formBasicEmail" className="mb-3 tedsc">
              <Form.Label className="triallabel">
                Email address <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="freswi"
              />
            </Form.Group>

            <Form.Group
              controlId="formBasicPassword"
              className="mb-3 tedsc"
              style={{ position: "relative" }}
            >
              <Form.Label className="triallabel">
                Password <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "56%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={togglePasswordVisibility}
              >
                {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
              </span>
              <p className="flex justify-end">
                <a
                  href="https://churchease.com/knowledgebaseview/66c29a221c347b9e6605f3e0"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#FE7064",
                    fontSize: "0.8em",
                    textDecoration: "none",
                  }}
                >
                  Password Guidelines
                </a>
              </p>
            </Form.Group>
            <Form.Group
              controlId="formBasicPassword"
              className="mb-3 tedsc"
              style={{ position: "relative" }}
            >
              <Form.Label className="triallabel ">
                Confirm Password <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type={isPasswordConfirmVisible ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "67%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={togglePasswordConfirmVisibility}
              >
                {isPasswordConfirmVisible ? <FaEyeSlash /> : <FaEye />}
              </span>
              {error && <p className="text-danger smalltext">{error}</p>}
            </Form.Group>

            <Form.Group controlId="formFirstName" className="mb-3 tedsc">
              <Form.Label className="triallabel">
                First Name <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formLastName" className="mb-3 tedsc">
              <Form.Label className="triallabel">
                Last Name <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formChurchName" className="mb-3 tedsc">
              <Form.Label className="triallabel">
                Church Name <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Church Name"
                value={churchName}
                onChange={(e) => setChurchName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formChurchDomain" className="mb-3 tedsc">
              <Form.Label className="triallabel">
                Church Domain <span className="myast">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Church Domain"
                value={churchDomain}
                onChange={(e) => setChurchDomain(e.target.value)}
              />
            </Form.Group>
            <div className="flex justify-center mt-3">
              <button
                className="jiluiloopo"
                style={{ padding: "0.6em 4.5em" }}
                onClick={handleProcess}
              >
                Get started
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpPageTrial;

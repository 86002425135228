import React, { useState, useEffect } from "react";
import axios from "axios";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";
import Badge from "react-bootstrap/Badge";
import Image from "react-bootstrap/Image";
import "./profile.css";
import Resizer from "react-image-file-resizer";
import Profilez from "../../assets/images/blank2.png";
import { useAppContext } from "../../context/appContext";
import { Modal } from "react-bootstrap";
import Alert from "../Alert/Alert";

const ChurchInformation = ({ show, setShow }) => {
  const { user, isLoading, showAlert, displayAlert, updateUser } =
    useAppContext();

  useEffect(() => {
    if (user?.aboutUs) setAboutUs(user?.aboutUs);
    if (user?.churchName) setChurchName(user?.churchName);
  }, [user]);

  const [email, setEmail] = useState(user.memail);
  const [churchDomain, setChurchDomain] = useState(user.churchDomain);
  const [firstName, setFirstName] = useState(user.mfirstName);
  const [lastName, setLastName] = useState(user.mlastName);
  const [phoneNumber, setPhoneNumber] = useState(user.mworkPhone);
  const [churchName, setChurchName] = useState();
  const [aboutUs, setAboutUs] = useState("");

  const [preview, setPreview] = useState("");
  const [taxNumber, setTaxNumber] = useState(user.taxNumber);
  const [website, setWebsite] = useState(user.website);
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState(
    user.primaryPhoneNumber
  );
  const [image, setImage] = useState(user.mProfilePicture);

  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");

  const { mProfilePicture, logoutUser } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const currentUser = {
      memail: email,
      mfirstName: firstName,
      mlastName: lastName,
      churchName: churchName,
      // country: country,
      aboutUs: aboutUs,
      mworkPhone: phoneNumber,
      mProfilePicture: image,
      website: website,
      taxNumber: taxNumber,
      churchDomain: churchDomain,
    };

    updateUser({ currentUser });
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(file));
    setUploadButtonText(file.name);
    //setValues({ ...values, loading: true });
    // resize
    Resizer.imageFileResizer(file, 520, 300, "JPEG", 100, 0, async (uri) => {
      try {
        let { data } = await authFetch.post("/members/upload-image", {
          image: uri,
        });

        setImage(data);
      } catch (err) {}
    });
  };

  const handleImageRemove = async (e) => {
    e.preventDefault();

    try {
      await authFetch.post("/members/remove-image", { image });
      setImage({});
      setPreview("");
      setUploadButtonText("Upload Removed");
    } catch (err) {
      console.error("Error in uploadDefaultImage function:", err);
    }
  };
  const [fullscreen, setFullscreen] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Church Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="leftgivediv">
          <form className="srgerge">
            {showAlert && <Alert />}

            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
              className="mb-3 pb-3"
            >
              <Image
                width={100}
                height={100}
                src={
                  user?.mProfilePicture?.Location
                    ? user?.mProfilePicture?.Location
                    : Profilez
                }
                onClick={handleImageRemove}
                roundedCircle
              />
              <div style={{ paddingBottom: 20 }}>
                {preview && (
                  <Badge
                    count="X"
                    onClick={handleImageRemove}
                    className="pointer"
                  >
                    <Image width={200} height={150} src={preview} />
                  </Badge>
                )}
              </div>
              <div className="flex flex-row gap-3">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="hed btn btn-outline-secondary btn-block">
                    Upload Logo
                  </Form.Label>
                  <Form.Control
                    type="file"
                    value={mProfilePicture}
                    name="image"
                    handleChange={handleImage}
                    onChange={handleImage}
                    accept="image/*"
                    className="formvalue"
                    hidden
                  />
                </Form.Group>

                <button
                  className="hed btn btn-outline-secondary btn-block"
                  onClick={handleImageRemove}
                >
                  Remove Logo
                </button>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>Church Name</Form.Label>
              <Form.Control
                type="text"
                name="churchName"
                placeholder="Church Name"
                value={churchName}
                onChange={(e) => setChurchName(e.target.value)}
                className="forminput"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Church Domain
              </Form.Label>
              <Form.Control
                type="text"
                name="churchDomain"
                placeholder="Church Domain"
                value={churchDomain}
                onChange={(e) => setChurchDomain(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Church Email
              </Form.Label>
              <Form.Control
                type="text"
                name="churchEmail"
                placeholder="Church Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>Website</Form.Label>
              <Form.Control
                type="text"
                name="website"
                placeholder="Website"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Church Phone Number
              </Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                placeholder="Phone"
                value={
                  primaryPhoneNumber?.length
                    ? primaryPhoneNumber
                    : "No data available"
                }
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="forminput"
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                Tax ID Number
              </Form.Label>
              <Form.Control
                type="text"
                name="taxNumber"
                placeholder="taxNumber"
                value={taxNumber?.length ? taxNumber : "No data available"}
                onChange={(e) => setTaxNumber(e.target.value)}
                className="forminput"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ fontWeight: "600" }}>
                How did you hear about us{" "}
              </Form.Label>
              <Form.Select
                value={aboutUs?.length ? aboutUs : user?.aboutUs}
                name="aboutUs"
                onChange={(e) => setAboutUs(e.target.value)}
              >
                <option value="" selected="selected"></option>
                <option value="SearchedOnline">Searched Online</option>
                <option value="ReferredBySomeone">Referred by Someone</option>
                <option value="SocialMedia">Social Media</option>
                <option value="YouContactedMe">You contacted me</option>
                <option value="Other">Other</option>
              </Form.Select>
            </Form.Group>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil3 " onClick={handleClose}>
          Close
        </button>
        <button className="jiluil" onClick={handleSubmit}>
          Update
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChurchInformation;

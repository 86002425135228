import React, { useState, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { FaAlignRight } from "react-icons/fa";
import { useAppContext } from "../../context/appContext";
import MemberContainer from "../MemberContainer/MemberContainer";
import "./searchcontainer.css";
import { FaCaretUp } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import Button from "react-bootstrap/Button";

const SearchContainer = () => {
  const [search1, setSearch] = useState("");
  const [advance, setAdvance] = useState(false);
  const [filter, setFilter] = useState(true);
  const [localSearch, setLocalSearch] = useState("");
  const navigate = useNavigate();

  const {
    members,
    isLoading,

    searchStatus,
    searchMarital,
    searchGender,
    searchCampus,
    searchAge,
    sort,
    handleChange,
    clearFilters,
  } = useAppContext();

  const searchmembers = members.filter(
    (item) =>
      item.mfirstName.toLowerCase().includes(search1) ||
      item.mlastName.toLowerCase().includes(search1)
  );

  const handleAdvance = () => {
    setAdvance(!advance);
    setFilter(!filter);
  };

  const createMembers = () => {
    navigate("/auth/createMember");
  };

  const handleSearch = (e) => {
    handleChange({ name: e.target.name, value: e.target.value });
    console.log(e.target.name);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLocalSearch("");
    clearFilters();
  };
  const debounce = () => {
    let timeoutID;
    return (e) => {
      setLocalSearch(e.target.value);
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        handleChange({ name: e.target.name, value: e.target.value });
      }, 1000);
    };
  };
  const optimizedDebounce = useMemo(() => debounce(), [debounce]);
  return (
    <>
      <Container fluid>
        <Row className="rowh">
          <Col className="searchcolor">
            <p className="simpsty">
              <FaAlignRight /> Members
            </p>
            {filter && (
              <div>
                <p className="simpsty2">Filter by Name</p>

                <Form.Control
                  type="text"
                  placeholder="Type member's name"
                  value={localSearch}
                  onChange={optimizedDebounce}
                  className="formwi"
                  name="search"
                />
              </div>
            )}
            <p className="simpsty3">
              SHOW MORE FILTER OPTIONS{" "}
              {advance ? (
                <FaCaretUp onClick={handleAdvance} />
              ) : (
                <FaCaretDown onClick={handleAdvance} />
              )}
            </p>
            {advance && (
              <>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Search</Form.Label>
                      <Form.Control
                        type="text"
                        name="search"
                        placeholder="Start Typing"
                        value={localSearch}
                        onChange={optimizedDebounce}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        name="searchStatus"
                        value={searchStatus}
                        onChange={handleSearch}
                      >
                        <option value="all">All</option>
                        <option value="Member">Member</option>
                        <option value="Visitor">Visitor</option>
                        <option value="NoLongerAttends">
                          No Longer Attends
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>Marital</Form.Label>
                      <Form.Select
                        name="searchMarital"
                        value={searchMarital}
                        onChange={handleSearch}
                      >
                        <option value="all">All</option>
                        <option value="Single">Single</option>
                        <option value="Engaged">Engaged</option>
                        <option value="Married">Married</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widowed">Widowed</option>
                        <option value="Separated">Separated</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>Gender</Form.Label>
                      <Form.Select
                        name="searchGender"
                        value={searchGender}
                        onChange={handleSearch}
                      >
                        <option value="all">All</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="formGroupPassword"
                    >
                      <Form.Label>Age</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Age"
                        name="searchAge"
                        value={searchAge}
                        onChange={handleSearch}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>Sort</Form.Label>
                      <Form.Select
                        name="sort"
                        value={sort}
                        onChange={handleSearch}
                      >
                        <option value="latest">Latest</option>
                        <option value="oldest">Oldest</option>
                        <option value="a-z">a-z</option>
                        <option value="z-a">z-a</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Button disabled={isLoading} onClick={handleSubmit}>
                    Clear Filter
                  </Button>
                </Form>
              </>
            )}
          </Col>
          <Col className="reddd">
            <div className="">
              <Button className="buttonner" onClick={createMembers}>
                Add Member
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <MemberContainer searchmembers={searchmembers} />
    </>
  );
};

export default SearchContainer;

import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import { Col } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import AddDynamicFields from "../../components/DynamicFields/AddDynamicFields";
import { toast } from "react-toastify";
import CustomToastContent from "../Toast/CustomToastContent";

const DynamicFields = ({ showx, setShowx }) => {
  const { logoutUser, user } = useAppContext();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const [dynamicFields, setDynamicFields] = useState([]);
  const [title, setTitle] = useState("");

  const handleSubmit = async (e) => {};
  const [show, setShow] = useState(false);
  const handleClose = () => setShowx(false);
  const handleShow = () => setShow(true);
  const [showAlertxxx, setShowAlertxxx] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const showAddFieldx = async () => {
    setShow(true);
  };

  const [clickedField, setClickedField] = useState("");
  const [clickedTitle, setClickedTitle] = useState("");
  const [fieldsData, setFieldsData] = useState({});
  const [fields, setFields] = useState([]);
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const handleEdit = (event, dynamicField, id) => {
    event.preventDefault();

    setClickedField(id);
    setClickedTitle(dynamicField.title);
    handleShow2();
    getCustomFieldById();
  };

  const handleDelete = (event, dynamicField, id) => {
    event.preventDefault();
    event.stopPropagation(); // Prevent event bubblingid

    setClickedField(id);
    setClickedTitle(dynamicField.title);
    handleShow1();
  };
  const deleteThisMember = async () => {
    try {
      const { data } = await authFetch.delete(
        `/dynamicfields/${clickedField}/${clickedTitle}`
      );

      if (data.msg === "Success! Field removed") {
        handleClose1();
        // setShowAlertxxx("Dynamic field deleted successfully");
        setDynamicFields(
          dynamicFields.filter((item) => item._id !== clickedField)
        );
        toast(
          <CustomToastContent title="Success" message="Delete Successful!" />
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDynamicFields();
  }, []);

  const handleCheckedChange = (index, isChecked) => {
    const updateFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, checked: isChecked };
      }
      return field;
    });
    setFields(updateFields);
  };

  const getDynamicFields = async () => {
    try {
      const { data } = await authFetch.get("/dynamicfields");

      if (data) {
        setDynamicFields(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeField = (index) => {
    const newFields = fields.filter((_, idx) => idx !== index);
    setFields(newFields);
  };

  const getCustomFieldById = async () => {
    try {
      const { data } = await authFetch.get(
        `/dynamicfields/${clickedField}/${clickedTitle}`
      );

      if (data.msg === "Success") {
        setFieldsData(data.field);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Function to handle the change in title of a specific field
  const handleTaskChange = (index, value) => {
    const updatedFields = fields.map((field, i) =>
      i === index ? { ...field, title: value } : field
    );
    setFields(updatedFields);
  };

  // Function to change the type of a specific field
  const handleTypeChange = (index, value) => {
    const updatedFields = fields.map((field, i) =>
      i === index ? { ...field, type: value } : field
    );
    setFields(updatedFields);
  };

  // Function to add a new field to the form
  const addTask = (event) => {
    event.preventDefault();
    const newField = { title: "", type: "text", options: [] };
    setFields([...fields, newField]);
  };

  // Function to add a new option to a specific field
  const addOptionToTask = (index, event) => {
    event.preventDefault();
    const updatedFields = fields.map((field, i) =>
      i === index ? { ...field, options: [...field.options, ""] } : field
    );
    setFields(updatedFields);
  };

  // Function to update the text of a specific option within a field
  const handleOptionChange = (taskIndex, optionIndex, value) => {
    const updatedFields = fields.map((field, i) => {
      if (i === taskIndex) {
        const updatedOptions = field.options.map((option, j) =>
          j === optionIndex ? value : option
        );
        return { ...field, options: updatedOptions };
      }
      return field;
    });
    setFields(updatedFields);
  };

  // Function to remove a specific option from a field
  const removeOption = (taskIndex, optionIndex) => {
    const updatedFields = fields.map((field, i) => {
      if (i === taskIndex) {
        const filteredOptions = field.options.filter(
          (_, j) => j !== optionIndex
        );
        return { ...field, options: filteredOptions };
      }
      return field;
    });
    setFields(updatedFields);
  };

  useEffect(() => {
    if (clickedField && clickedTitle) {
      getCustomFieldById();
    }
  }, [clickedField, clickedTitle]);

  useEffect(() => {
    // Check if fieldsData is not empty and has a title in the first object
    if (fieldsData) {
      setTitle(fieldsData?.title);
    }

    // Check if fieldsData is not empty and has a 'fields' array in the first object
    if (fieldsData?.fields?.length > 0) {
      setFields(fieldsData?.fields);
    }
  }, [fieldsData]);

  const handleUpdateField = async (e) => {
    e.preventDefault();
    try {
      if (!title) return setError(true);

      const dataxx = {
        title,
        fields,
        churchName: user.churchName,
        clickedField,
      };

      await authFetch.patch("/dynamicfields", dataxx);
      handleClose2();

      getDynamicFields();
      setTitle("");
      setFields([]);
      toast(
        <CustomToastContent title="Success" message="Update Successful!" />
      );
    } catch (err) {
      console.log(err);
    }
  };
  const [fullscreen, setFullscreen] = useState(true);

  const handleClosex = () => setShowx(false);

  return (
    <Modal show={showx} fullscreen={fullscreen} onHide={handleClosex}>
      <Modal.Header closeButton>
        <Modal.Title>Dynamic Fields</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Container fluid>
            <Row>
              <div className="flex justify-end gap-3 mt-5">
                <button className="jiluil" onClick={showAddFieldx}>
                  New Field
                </button>
              </div>
            </Row>
            {dynamicFields && dynamicFields.length > 0 ? (
              <Row className="tabw">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "1em",
                    color: "var(--primary-color)",
                    paddingTop: "2rem",
                  }}
                >
                  {dynamicFields.length ? (
                    <p>
                      {dynamicFields.length} record
                      {dynamicFields.length > 1 ? "s" : ""} found
                    </p>
                  ) : (
                    <p>No Saved View</p>
                  )}
                </p>
                {showAlertxxx && (
                  <p className="inlinesuccess">{showAlertxxx}</p>
                )}
                <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                  <thead>
                    <tr className="trdataheader">
                      <th>FIELD NAME</th>
                    </tr>
                  </thead>
                  {dynamicFields?.length > 0 &&
                    dynamicFields.map((dynamicField, index) => (
                      <tbody key={index}>
                        <tr className="trdata">
                          {/* Use index as key for each row */}
                          <td>{dynamicField.title}</td>
                          <td className="rightco">
                            <button
                              className="jiluil"
                              onClick={(event) =>
                                handleEdit(
                                  event,
                                  dynamicField,
                                  dynamicField._id // Assuming 'containerId' is stored directly in each 'dynamicField'
                                )
                              }
                            >
                              Edit
                            </button>
                            <button
                              className="jiluil3"
                              onClick={(event) =>
                                handleDelete(
                                  event,
                                  dynamicField,
                                  dynamicField._id // Assuming 'containerId' is stored directly in each 'dynamicField'
                                )
                              }
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </Table>
              </Row>
            ) : (
              <div className="noDatax">
                <p className="noDataBackup">No data available for display.</p>
                <div
                  className="flex align-center justify-center flex-column w-100"
                  style={{ textAlign: "center" }}
                >
                  <p className="qubulkf2">
                    Check our{" "}
                    <a
                      href="https://churchease.com/knowledgebaseview/666fb786c20672005705f40a"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "var(--secondary-color)",
                        textDecoration: "none",
                      }}
                    >
                      knowledge base
                    </a>{" "}
                    for more information on how to create dynamic fields.
                  </p>
                </div>
              </div>
            )}
          </Container>{" "}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              This action will permanently delete this field. This action cannot
              be reversed.
            </Modal.Body>
            <Modal.Footer>
              <button className="jiluil" onClick={handleClose1}>
                Close
              </button>
              <button className="jiluil3" onClick={deleteThisMember}>
                Delete
              </button>
            </Modal.Footer>
          </Modal>
          <AddDynamicFields
            show={show}
            setShow={setShow}
            uploadSuccessFunc={getDynamicFields}
          />
          <Modal show={show2} onHide={handleClose2} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Update {clickedTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container fluid>
                {error && (
                  <p className="inlineerror">
                    Please complete the required fields to proceed!!
                  </p>
                )}

                {error2 && (
                  <p className="inlineerror">
                    Description is above the required length!!
                  </p>
                )}
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicTitle" className="mb-3">
                    <Form.Label>Category Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Category Name"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>

                  <div>
                    {fields.map((task, index) => (
                      <Row key={index} className="mb-3">
                        <Col md={6}>
                          <Form.Label>Field Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="e.g. First Name"
                            value={task.title}
                            onChange={(e) =>
                              handleTaskChange(index, e.target.value)
                            }
                          />
                        </Col>
                        <Col md={4}>
                          <Form.Label>Field Type</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={task.type}
                            onChange={(e) =>
                              handleTypeChange(index, e.target.value)
                            }
                          >
                            <option>Choose...</option>
                            <option value="text">Short answer</option>
                            <option value="textarea">Paragraph</option>
                            <option value="radio">Multiple Choice</option>
                            <option value="checkbox">Checkboxes</option>
                          </Form.Select>
                        </Col>
                        {/* <Col>
                          <FaTimes
                            onClick={() => removeField(index)}
                            style={{ cursor: "pointer" }}
                          />
                        </Col> */}
                        <Col
                          md={2}
                          className="flex flex-row justify-between "
                          style={{ alignItems: "end" }}
                        >
                          <Form.Check
                            type="checkbox"
                            label="Required"
                            checked={task.checked}
                            onChange={(e) =>
                              handleCheckedChange(index, e.target.checked)
                            }
                          />
                          {index > 0 && (
                            <p
                              onClick={() => removeField(index)}
                              style={{
                                marginBottom: "0px",
                                color: "red",
                                cursor: "pointer",
                              }}
                            >
                              Remove
                            </p>
                          )}
                        </Col>

                        {task.type === "radio" || task.type === "checkbox" ? (
                          <>
                            {task.options.map((option, optionIndex) => (
                              <Form.Group
                                key={optionIndex}
                                className="mb-3 mt-3 flex items-center justify-center gap-3 flex-row"
                              >
                                <Form.Check
                                  type={task.type}
                                  name={`group-${index}`}
                                />
                                <Form.Control
                                  type="text"
                                  placeholder={`Option ${optionIndex + 1}`}
                                  value={option}
                                  onChange={(e) =>
                                    handleOptionChange(
                                      index,
                                      optionIndex,
                                      e.target.value
                                    )
                                  }
                                />
                                <FaTimes
                                  onClick={() =>
                                    removeOption(index, optionIndex)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </Form.Group>
                            ))}
                            <button
                              className="jiluil3 mt-3 mb-3"
                              onClick={(event) => addOptionToTask(index, event)}
                            >
                              Add Option
                            </button>
                            <hr></hr>
                          </>
                        ) : null}
                      </Row>
                    ))}
                    <hr></hr>
                    <button className="jiluil mb-3s" onClick={addTask}>
                      Add Field
                    </button>
                  </div>
                </Form>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <button className="jiluil" onClick={handleClose2}>
                Close
              </button>
              <button className="jiluil3" onClick={handleUpdateField}>
                Update
              </button>
            </Modal.Footer>
          </Modal>{" "}
        </>
      </Modal.Body>
      <Modal.Footer>
        <button className="jiluil" onClick={handleClosex}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DynamicFields;

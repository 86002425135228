import React, { useState, useEffect } from "react";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./profile.css";
import Resizer from "react-image-file-resizer";
import { useAppContext } from "../../../context/appContext";
import Alert from "../../../components/Alert/Alert.js";
import Profilez from "../../../assets/images/blank2.png";
import { Nav, Table, Col } from "react-bootstrap";
import Profiles from "../../../components/Profiles/Profiles";
import ChurchAddress from "../../../components/Profiles/ChurchAddress";
import MailChimp from "../../../components/Profiles/MailChimp";
import TextSetup from "../../../components/Profiles/TextSetup.js";
import MultiFactorAuth from "../../../components/Profiles/MultiFactorAuth";
import Intuit from "../../../components/Profiles/Intuit";
import ChurchCampuses from "../../../components/Profiles/ChurchCampuses";
import Campuses from "../../../components/Profiles/Campuses.js";
import ExternalIntegrations from "../../../components/Profiles/ExternalIntegrations";
import Location from "../../../components/Profiles/Location.js";
import Customize from "../../../components/Profiles/Customize.js";
import DynamicFields from "../../../components/Profiles/DynamicFields.js";
import ArchivedList from "../../../components/Profiles/ArchivedList";
import ChurchInformation from "../../../components/Profiles/ChurchInformation.js";
import {
  FcAddressBook,
  FcBusiness,
  FcCdLogo,
  FcDepartment,
  FcHeatMap,
  FcLock,
  FcMms,
  FcPhone,
  FcSms,
  FcSteam,
  FcTemplate,
  FcTimeline,
} from "react-icons/fc";
import { FaCompass } from "react-icons/fa";
import EmailSettings from "../../../components/Profiles/EmailSettings.js";
import AccessSecurity from "../../../components/Profiles/AccessSecurity.js";
import TextMeter from "../../../components/Profiles/TextMeter.js";

const Profile = () => {
  const { user, isLoading, showAlert, displayAlert, updateUser } =
    useAppContext();
  const [selectedSection, setSelectedSection] = useState("home"); // Default to "home"
  const [isFree, setIsFree] = useState(false);

  useEffect(() => {
    if (user.subscription && user.subscription.length > 0) {
      if (user.subscription[0].plan.nickname === "Free") {
        setIsFree(true);
      } else {
        setIsFree(false);
      }
    } else {
      //  / console.log("The user does not have any active subscriptions.");
    }
  }, [user.subscription]);

  const isAdmin = user.role.some((role) =>
    ["Super Admin", "Admin"].includes(role.roleName)
  );

  const [show, setShow] = useState(false);
  const handleChurchInformation = () => {
    setShow(true);
  };

  const [show1, setShow1] = useState(false);
  const handleChurchAddress = () => {
    setShow1(true);
  };

  const [show2, setShow2] = useState(false);
  const handleLocation = () => {
    setShow2(true);
  };

  const [show3, setShow3] = useState(false);
  const handleBranding = () => {
    setShow3(true);
  };

  const [show4, setShow4] = useState(false);
  const handleCampuses = () => {
    setShow4(true);
  };

  const [show5, setShow5] = useState(false);
  const handlePhone = () => {
    setShow5(true);
  };

  const [show9, setShow9] = useState(false);
  const handleSecurity = () => {
    setShow9(true);
  };

  const [show6, setShow6] = useState(false);
  const handleText = () => {
    setShow6(true);
  };

  const [show10, setShow10] = useState(false);
  const handleMeter = () => {
    setShow10(true);
  };

  const [show7, setShow7] = useState(false);
  const handleDynamicFields = () => {
    setShow7(true);
  };

  const [show8, setShow8] = useState(false);
  const handleEmail = () => {
    setShow8(true);
  };
  const [show11, setShow11] = useState(false);
  const handleArchive = () => {
    setShow11(true);
  };

  return (
    <>
      <Container fluid className="container33">
        <Row className="courseheader">
          <Table responsive>
            <tbody>
              <tr>
                <td>
                  <p onClick={handleChurchInformation} className="profile45">
                    <FcDepartment /> Church Information
                  </p>
                  {/* {isAdmin && (
                    <p onClick={handleChurchAddress} className="profile45">
                      <FcAddressBook />
                      Church Address
                    </p>
                  )} */}
                  {isAdmin && (
                    <p onClick={handleLocation} className="profile45">
                      <FcHeatMap />
                      Localization
                    </p>
                  )}

                  {isAdmin && (
                    <p onClick={handleDynamicFields} className="profile45">
                      <FcTemplate />
                      Dynamic Fields
                    </p>
                  )}
                  {isAdmin && (
                    <p onClick={handleEmail} className="profile45">
                      <FcTimeline />
                      Church Member Access
                    </p>
                  )}
                  {isAdmin && (
                    <p onClick={handleArchive} className="profile45">
                      <FcSteam />
                      Archived Members
                    </p>
                  )}
                </td>
                <td>
                  {/* New Column Content Here */}
                  {isAdmin && (
                    <p onClick={handleBranding} className="profile45">
                      <FcCdLogo />
                      Branding
                    </p>
                  )}
                  {isAdmin && (
                    <p onClick={handleCampuses} className="profile45">
                      <FcBusiness />
                      Church Campus
                    </p>
                  )}
                  {isAdmin && (
                    <p onClick={handlePhone} className="profile45">
                      <FcPhone />
                      MultiFactor Authentication
                    </p>
                  )}
                  {isAdmin && (
                    <p onClick={handleText} className="profile45">
                      <FcMms />
                      Text Setup
                    </p>
                  )}
                  {isAdmin && (
                    <p onClick={handleMeter} className="profile45">
                      <FcSms />
                      Text Meter
                    </p>
                  )}
                  {/* {isAdmin && (
                    <p onClick={handleSecurity} className="profile45">
                      <FcLock />
                      Access Logs
                    </p>
                  )} */}
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </Container>
      <ChurchInformation show={show} setShow={setShow} />
      <ChurchAddress show={show1} setShow={setShow1} />
      <Location show={show2} setShow={setShow2} />
      <Customize show={show3} setShow={setShow3} />
      <Campuses show4={show4} setShow4={setShow4} />
      <MultiFactorAuth show={show5} setShow={setShow5} />
      <TextSetup show={show6} setShow={setShow6} />
      <TextMeter show={show10} setShow={setShow10} />
      <DynamicFields showx={show7} setShowx={setShow7} />
      <EmailSettings show={show8} setShow={setShow8} />
      <AccessSecurity show={show9} setShow={setShow9} />
      <ArchivedList show={show11} setShow={setShow11} />
    </>
  );
};

export default Profile;

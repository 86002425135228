import React, { useState, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import "./peopleheader.css";
import { FaCaretDown, FaMinus, FaTimes } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import AddShareLink from "./AddShareLink";
import axios from "axios";

const PeopleHeader = () => {
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const {
    isLoading,
    user,
    searchStatus,
    searchMarital,
    searchGender,
    searchAnniversaryMonth,
    searchBirthMonth,
    searchCampus,
    sort,
    handleChange,
    clearFilters,
    logoutUser,
  } = useAppContext();

  const [advance, setAdvance] = useState(false);
  const [filter, setFilter] = useState(true);
  const [localSearch, setLocalSearch] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const handleAdvance = () => {
    setAdvance(!advance);
    setFilter(!filter);
  };

  const createPeople = () => {
    navigate("/auth/createpeople");
  };

  const handleSearch = (e) => {
    handleChange({ name: e.target.name, value: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLocalSearch("");
    clearFilters();
  };

  const debounce = () => {
    let timeoutID;
    return (e) => {
      setLocalSearch(e.target.value);
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        handleChange({ name: e.target.name, value: e.target.value });
      }, 1000);
    };
  };

  const optimizedDebounce = useMemo(() => debounce(), [debounce]);

  const handleClear = () => {
    setLocalSearch("");
    clearFilters();
  };

  const clearSearchFilter = (filterType) => {
    switch (filterType) {
      case "gender":
        handleChange({ name: "searchGender", value: "" });
        break;
      case "status":
        handleChange({ name: "searchStatus", value: "all" });
        break;
      case "marital":
        handleChange({ name: "searchMarital", value: "all" });
        break;
      case "anniversaryMonth":
        handleChange({ name: "searchAnniversaryMonth", value: "all" });
        break;
      case "birthMonth":
        handleChange({ name: "searchBirthMonth", value: "all" });
        break;
      case "campus":
        handleChange({ name: "searchCampus", value: "all" });
        break;
      case "search":
        setLocalSearch(""); // Also reset the local search state
        handleChange({ name: "search", value: "" });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="rowhs">
          <div className="flex flex-column items-center">
            {advance ? (
              ""
            ) : (
              <>
                <div className="flex flex-row items-center justify-center gap-3 w-100 pt-4">
                  {filter && (
                    <Form.Control
                      type="text"
                      placeholder="Search by first or last name, email address and phone number"
                      value={localSearch}
                      onChange={optimizedDebounce}
                      className="formwi w-50"
                      name="search"
                      style={{ height: "50px" }} // Adjust the height to match the button
                    />
                  )}
                  {advance ? (
                    ""
                  ) : (
                    <button className="jiluil" onClick={createPeople}>
                      Add Member
                    </button>
                  )}
                </div>
                <div className="flex items-center justify-center gap-5">
                  <p className="mt-2 urlccc" onClick={handleShow}>
                    Shareable Link
                  </p>
                  <p className="mt-2 urlccc" onClick={handleAdvance}>
                    Advanced Search
                  </p>
                  {searchGender && (
                    <div className="flex items-center gap-2 text-black">
                      <span>{`Gender: ${searchGender}`}</span>
                      <button
                        onClick={() => clearSearchFilter("gender")}
                        className="text-red-500"
                      >
                        X
                      </button>
                    </div>
                  )}
                  {searchStatus && searchStatus !== "all" && (
                    <div className="flex items-center gap-2 text-black">
                      <span>{`Status: ${searchStatus}`}</span>
                      <button
                        onClick={() => clearSearchFilter("status")}
                        className="text-red-500"
                      >
                        X
                      </button>
                    </div>
                  )}
                  {searchMarital && searchMarital !== "all" && (
                    <div className="flex items-center gap-2 text-black">
                      <span>{`Marital: ${searchMarital}`}</span>
                      <button
                        onClick={() => clearSearchFilter("marital")}
                        className="text-red-500"
                      >
                        X
                      </button>
                    </div>
                  )}
                  {searchAnniversaryMonth &&
                    searchAnniversaryMonth !== "all" && (
                      <div className="flex items-center gap-2 text-black">
                        <span>{`Anniversary: ${searchAnniversaryMonth}`}</span>
                        <button
                          onClick={() => clearSearchFilter("anniversaryMonth")}
                          className="text-red-500"
                        >
                          X
                        </button>
                      </div>
                    )}
                  {searchBirthMonth && searchBirthMonth !== "all" && (
                    <div className="flex items-center gap-2 text-black">
                      <span>{`Birth Month: ${searchBirthMonth}`}</span>
                      <button
                        onClick={() => clearSearchFilter("birthMonth")}
                        className="text-red-500"
                      >
                        X
                      </button>
                    </div>
                  )}
                  {searchCampus && searchCampus !== "all" && (
                    <div className="flex items-center gap-2 text-black">
                      <span>{`Campus: ${searchCampus}`}</span>
                      <button
                        onClick={() => clearSearchFilter("campus")}
                        className="text-red-500"
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          {advance && (
            <div style={{ width: "100%" }}>
              <div
                className="simpsty3xx"
                style={{
                  marginBottom: "0",
                  marginRight: "20px",
                  fontSize: "100%",
                }}
                onClick={handleAdvance}
              >
                <div>
                  {advance ? (
                    <div className="flex gap-3">
                      <FaMinus
                        onClick={handleAdvance}
                        style={{ fontSize: "20px" }}
                      />
                      <FaTimes
                        onClick={handleClear}
                        style={{ fontSize: "20px" }}
                      />
                    </div>
                  ) : (
                    <FaCaretDown onClick={handleAdvance} />
                  )}
                </div>
              </div>
              <Form>
                <Row className="mb-3 mt-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label
                      style={{
                        fontWeight: "600",
                        fontSize: "1em",
                        color: "black",
                      }}
                    >
                      Sort
                    </Form.Label>
                    <Form.Select
                      name="sort"
                      value={sort}
                      onChange={handleSearch}
                    >
                      <option value="latest">Latest</option>
                      <option value="oldest">Oldest</option>
                      <option value="a-z">a-z</option>
                      <option value="z-a">z-a</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label
                      style={{
                        fontWeight: "600",
                        fontSize: "1em",
                        color: "black",
                      }}
                    >
                      Status
                    </Form.Label>
                    <Form.Select
                      name="searchStatus"
                      value={searchStatus}
                      onChange={handleSearch}
                    >
                      <option value="all">All</option>
                      <option value="Member">Member</option>
                      <option value="Visitor">Visitor</option>
                      <option value="NoLongerAttends">No Longer Attends</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label
                      style={{
                        fontWeight: "600",
                        fontSize: "1em",
                        color: "black",
                      }}
                    >
                      Marital
                    </Form.Label>
                    <Form.Select
                      name="searchMarital"
                      value={searchMarital}
                      onChange={handleSearch}
                    >
                      <option value="all">All</option>
                      <option value="Single">Single</option>
                      <option value="Engaged">Engaged</option>
                      <option value="Married">Married</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                      <option value="Separated">Separated</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label
                      style={{
                        fontWeight: "600",
                        fontSize: "1em",
                        color: "black",
                      }}
                    >
                      Gender
                    </Form.Label>
                    <Form.Select
                      name="searchGender"
                      value={searchGender}
                      onChange={handleSearch}
                    >
                      <option value="all">All</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label
                      style={{
                        fontWeight: "600",
                        fontSize: "1em",
                        color: "black",
                      }}
                    >
                      Anniversary
                    </Form.Label>
                    <Form.Select
                      name="searchAnniversaryMonth"
                      value={searchAnniversaryMonth}
                      onChange={handleSearch}
                    >
                      <option value="all">All</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label
                      style={{
                        fontWeight: "600",
                        fontSize: "1em",
                        color: "black",
                      }}
                    >
                      Birthday
                    </Form.Label>
                    <Form.Select
                      name="searchBirthMonth"
                      value={searchBirthMonth}
                      onChange={handleSearch}
                    >
                      <option value="all">All</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label
                      style={{
                        fontWeight: "600",
                        fontSize: "1em",
                        color: "black",
                      }}
                    >
                      Search
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="search"
                      placeholder="Start Typing"
                      value={localSearch}
                      onChange={optimizedDebounce}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label
                      style={{
                        fontWeight: "600",
                        fontSize: "1em",
                        color: "black",
                      }}
                    >
                      Campus
                    </Form.Label>
                    <Form.Select
                      name="searchCampus"
                      value={searchCampus}
                      onChange={handleSearch}
                    >
                      <option value="all">All</option>
                      {user.churchCampuses.map((campus) => (
                        <option
                          key={campus.campusName}
                          value={campus.campusName}
                        >
                          {campus.campusName}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>

                <div id="checjit">
                  <Button
                    disabled={isLoading}
                    className="buttonner1222"
                    onClick={handleSubmit}
                  >
                    Clear Filter
                  </Button>

                  <Button className="buttonner122" onClick={createPeople}>
                    Add Member
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Row>
        <AddShareLink
          show={show}
          setShow={setShow}

          // shortenedUrl={shortenedUrl}
        />
      </Container>
    </>
  );
};

export default PeopleHeader;

import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavDropdown } from "react-bootstrap";

import { NavLink, useLocation, useMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import "./navbar.css";
import Logo from "../Logo/Logo";

import {
  FcCamera,
  FcCollaboration,
  FcFaq,
  FcFile,
  FcFlowChart,
  FcHome,
  FcLike,
  FcMoneyTransfer,
  FcMultipleDevices,
  FcNews,
  FcPodiumWithAudience,
  FcPodiumWithSpeaker,
  FcReadingEbook,
  FcSerialTasks,
  FcSettings,
  FcVideoProjector,
} from "react-icons/fc";

import {
  FaAddressBook,
  FaBible,
  FaCalculator,
  FaCalendar,
  FaChartPie,
  FaChild,
  FaFont,
  FaMapMarked,
  FaMoneyBill,
  FaPen,
  FaPeopleArrows,
  FaPray,
  FaRunning,
  FaUserAlt,
  FaVideo,
} from "react-icons/fa";

function Header() {
  const location = useLocation();
  const isActive = (pathname) => location.pathname.includes(pathname);
  const [show, setShow] = useState(false);

  const showDropdown = (e) => {
    setShow(!show);
  };

  const hideDropdown = (e) => {
    setShow(false);
  };
  return (
    <Navbar expand="lg" className="bg-body-light grouphome">
      <Container>
        <Navbar.Brand href="#home">
          <Link to="/">
            <Logo />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="flex justify-end w-100 gap-3">
            {/* <NavLink
              to="/"
              className={() =>
                location.pathname === "/" ? "linkmain2" : "linknavhome2"
              }
            >
              HOME
            </NavLink> */}
            <NavDropdown
              title="PRODUCT"
              id="basic-nav-dropdown"
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
              className={`${
                isActive("/product") ? "linkmain2" : "linknavhome2"
              } custom-nav-dropdown `}
              style={{ fontSize: "1em", color: "#243060", fontWeight: "600" }}
            >
              <LinkContainer to="/product/people">
                <NavDropdown.Item active={isActive("/product/people")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaUserAlt
                        className="iconloo"
                        style={{ color: "#909fdb" }}
                      />{" "}
                      <span className="fbnb">People</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/church-giving">
                <NavDropdown.Item active={isActive("/product/church-giving")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaMoneyBill
                        className="iconloo"
                        style={{ color: "#B53BE0" }}
                      />
                      <span className="fbnb">Church Giving</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/groups">
                <NavDropdown.Item active={isActive("/product/groups")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaPeopleArrows
                        className="iconloo"
                        style={{ color: "#909fdb" }}
                      />{" "}
                      <span className="fbnb">Group</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/ministry">
                <NavDropdown.Item active={isActive("/product/ministry")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaBible
                        className="iconloo"
                        style={{ color: "#F2CA50" }}
                      />{" "}
                      <span className="fbnb">Ministry</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/messaging">
                <NavDropdown.Item active={isActive("/product/messaging")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaChartPie
                        className="iconloo"
                        style={{ color: "#5D79DA" }}
                      />{" "}
                      <span className="fbnb">Two-Way Communication</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/followup">
                <NavDropdown.Item active={isActive("/product/followup")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaRunning
                        className="iconloo"
                        style={{ color: "#243060" }}
                      />{" "}
                      <span className="fbnb">Follow-Up</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/children">
                <NavDropdown.Item active={isActive("/product/children")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaChild
                        className="iconloo"
                        style={{ color: "#767DE8" }}
                      />{" "}
                      <span className="fbnb">Children</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/service-planner">
                <NavDropdown.Item active={isActive("/product/service-planner")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaMapMarked
                        className="iconloo"
                        style={{ color: "#D68E29" }}
                      />{" "}
                      <span className="fbnb">Service Planner</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/forms">
                <NavDropdown.Item active={isActive("/product/forms")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaFont
                        className="iconloo"
                        style={{ color: "#A43329" }}
                      />{" "}
                      <span className="fbnb">Forms</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/events">
                <NavDropdown.Item active={isActive("/product/events")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaCalendar
                        className="iconloo"
                        style={{ color: "#FE7064" }}
                      />{" "}
                      <span className="fbnb">Events</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/livestream">
                <NavDropdown.Item active={isActive("/product/livestream")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaVideo
                        className="iconloo"
                        style={{ color: "#B53BE0" }}
                      />{" "}
                      <span className="fbnb">Livestream</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>

              <LinkContainer to="/product/fellowship">
                <NavDropdown.Item active={isActive("/product/fellowship")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaPray
                        className="iconloo"
                        style={{ color: "#45877B" }}
                      />{" "}
                      <span className="fbnb">Fellowship</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/church-accounting">
                <NavDropdown.Item
                  active={isActive("/product/church-accounting")}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaCalculator
                        className="iconloo"
                        style={{ color: "#3F98AB" }}
                      />{" "}
                      <span className="fbnb">Church Accounting</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/learning-center">
                <NavDropdown.Item active={isActive("/product/learning-center")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FaPen className="iconloo" style={{ color: "#9D4072" }} />{" "}
                      <span className="fbnb">Learning Center</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            {/* <NavDropdown
              title="PRODUCT"
              id="nav-dropdown-products"
              className={`${
                isActive("/product") ? "linkmain2" : "linknavhome2"
              } custom-nav-dropdown`}
              style={{ fontSize: "0.9em", color: "#243060" }}
            >
              

             
              
        
             
             
             
             
              
              <LinkContainer to="/product/grow">
                <NavDropdown.Item active={isActive("/product/grow")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FcPodiumWithAudience className="iconloo" />{" "}
                      <span className="fbnb">Prayer</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/grow">
                <NavDropdown.Item active={isActive("/product/grow")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FcPodiumWithSpeaker className="iconloo" />{" "}
                      <span className="fbnb">Testimony</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/product/grow">
                <NavDropdown.Item active={isActive("/product/grow")}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Align items vertically
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <FcMultipleDevices className="iconloo" />{" "}
                      <span className="fbnb">Learning Center</span>
                    </div>
                  </div>
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown> */}
            <NavLink
              to="/pricing"
              className={() =>
                location.pathname === "/pricing" ? "linkmain2" : "linknavhome2"
              }
            >
              PRICING
            </NavLink>

            <NavLink
              to="/free"
              className={() =>
                location.pathname === "/free" ? "linkmain2" : "linknavhome2"
              }
            >
              FREE TRIAL
            </NavLink>
            <NavLink
              to="/blog"
              className={() =>
                location.pathname === "/blog" ? "linkmain2" : "linknavhome2"
              }
            >
              BLOG
            </NavLink>

            <div className="flex justify-center gap-4 items-center">
              <NavLink>
                <Link to="/login">
                  <button className="jiluil3">LOGIN</button>
                </Link>
              </NavLink>

              <a
                href="https://calendly.com/churchease/demo-churchease"
                target="_blank"
                rel="noopener noreferrer"
                className="jidjdd"
              >
                <button className="jiluil">BOOK A DEMO</button>
              </a>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;

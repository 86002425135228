import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import axios from "axios";
import { Helmet } from "react-helmet";
import "react-datepicker/dist/react-datepicker.css";
import { FaThumbsUp } from "react-icons/fa";

const SuccessRedirect = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>Profile Created</title>
        <meta
          name="description"
          content="Contributions Successful - Churchease"
        />
      </Helmet>

      <Container fluid className="contWwainer2">
        <Row style={{ padding: "0", margin: "0" }}>
          <div className="flex items-center flex-column wrrerxxx">
            <div className="flex flex-column items-center justify-center h-100">
              <div className="flex flex-column items-center">
                <FaThumbsUp className="mt-5" size={100} color="white" />
                <h1 className="text-center mt-5 text-white">
                  Profile Created Successfully!
                </h1>
              </div>
              <button className="mt-3 jiluil344" onClick={goHome}>
                Return to home page
              </button>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SuccessRedirect;

import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../context/appContext";
import nodd from "../../../assets/newi/nodd.png";
// import "../learning.css";
import Table from "react-bootstrap/Table";

import AddForm from "../../../components/Forms/AddForm";
import EditForm from "../../../components/Forms/EditForm";
import DeleteForm from "../../../components/Forms/DeleteForm";
import PublishForm from "../../../components/Forms/PublishForm";
import ShareForm from "../../../components/Forms/ShareForm";
import AssignForm from "../../../components/Forms/AssignForm";

const Forms = () => {
  const { logoutUser } = useAppContext();

  const navigate = useNavigate();

  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const loadCourse = async () => {
    const { data } = await authFetch.get(`/forms`);

    setForms(data.forms);
  };

  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [memberCount, setMemberCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  const [tag, setTag] = useState([]);
  const [member, setMember] = useState("");
  const [group, setGroup] = useState([]);

  useEffect(() => {
    loadCourse();
  }, []);

  const uploadSuccessFunc = () => {
    loadCourse();
  };

  const publishedForm = () => {
    loadCourse();
  };

  const unpublishedForm = () => {
    loadCourse();
  };

  const handleShow = (event, form) => {
    event.preventDefault();
    event.stopPropagation();
    setShow(true);
    setSelectedForm(form);
  };

  const handleShow5 = (event, form) => {
    event.preventDefault();
    event.stopPropagation();
    setShow5(true);
    setSelectedForm(form);
  };

  const handleShow6 = (event, form) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedForm(form);
    setShow6(true);
  };

  const handleShow7 = (event, form) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedForm(form);
    setShow7(true);
  };

  const AssignedCourse = () => {
    navigate("/auth/assignedcourse");
  };

  const addPlanner = (id) => {
    setShow2(true);
  };

  const handleView = (event, form) => {
    event.preventDefault();
    event.stopPropagation();
    setShow3(true);
    setSelectedForm(form);
  };

  const handleCopy = async (event, form) => {
    event.preventDefault();
    event.stopPropagation();

    const copyForm = { ...form };
    delete copyForm._id;
    copyForm.title += " (copy)";
    try {
      const response = await authFetch.post("/forms", copyForm);
      if (response.status === 200 || response.status === 201) {
        loadCourse();
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error copying form:", error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="courseheader">
          <Col>
            <p className="sahs">Forms</p>
          </Col>
        </Row>
        <Row className="sssssssecwef">
          <div className="rt6hi8">
            <button className="jiluil" onClick={addPlanner}>
              New Form
            </button>
          </div>
        </Row>
        <Row>
          <div>
            {forms && forms.length > 0 ? (
              <Row className="tabw">
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "1em",
                    color: "var(--primary-color)",
                    paddingTop: "3rem",
                  }}
                >
                  {forms.length ? (
                    <p>
                      {forms.length} record{forms.length > 1 ? "s" : ""} found
                    </p>
                  ) : (
                    <p>No Saved View</p>
                  )}
                </p>
                <Table hover responsive style={{ backgroundColor: "#f7f7f7" }}>
                  {forms.map((form, index) => (
                    <tbody>
                      <tr key={index} className="trdata">
                        <td>{form.title}</td>
                        <td className="rightco">
                          <button
                            className="q3rq3r23"
                            onClick={(event) => handleView(event, form)}
                          >
                            Edit
                          </button>
                          <button
                            className="q3rq3r23"
                            onClick={(event) => handleCopy(event, form)}
                          >
                            Copy
                          </button>
                          <button
                            className={form.published ? "jiluil3" : "jiluil"}
                            onClick={(event) => handleShow5(event, form)}
                          >
                            {form.published ? "Unpublish" : "Publish"}
                          </button>

                          {form.published && (
                            <button
                              className="jiluil3"
                              onClick={(event) => handleShow6(event, form)}
                            >
                              Share
                            </button>
                          )}

                          {form.published && (
                            <button
                              className="jiluil3"
                              onClick={(event) => handleShow7(event, form)}
                            >
                              Assign
                            </button>
                          )}

                          <button
                            className="jiluil2"
                            onClick={(event) => handleShow(event, form)}
                          >
                            Delete
                          </button>
                        </td>

                        <td>
                          <div className="flex gap-3">
                            <Link to={`/auth/formresponses/${form._id}`}>
                              <button className="jiluil">View Response</button>
                            </Link>
                            <Link to={`/auth/summaryformresponse/${form._id}`}>
                              <button className="jiluil">
                                Summary Response
                              </button>
                            </Link>
                          </div>
                        </td>
                        {/* <td>
                          <Link to={`/auth/summaryformresponse/${form._id}`}>
                            <button className="jiluil">Summary Response</button>
                          </Link>
                        </td> */}
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </Row>
            ) : (
              <div className="noData">
                <img src={nodd} alt="no data" />
                <p className="noDataBackup">No data available for display.</p>
              </div>
            )}
          </div>
        </Row>
      </Container>
      <AddForm
        show={show2}
        setShow={setShow2}
        uploadSuccessFunc={uploadSuccessFunc}
      />
      <EditForm
        show={show3}
        setShow={setShow3}
        selectedForm={selectedForm}
        uploadSuccessFunc={uploadSuccessFunc}
      />

      <DeleteForm
        show={show}
        setShow={setShow}
        selectedForm={selectedForm}
        uploadSuccessFunc={uploadSuccessFunc}
      />

      <PublishForm
        show={show5}
        AssignPlanner
        setShow={setShow5}
        selectedForm={selectedForm}
        uploadSuccessFunc={uploadSuccessFunc}
        publishedForm={publishedForm}
        unpublishedForm={unpublishedForm}
      />

      <ShareForm
        show={show6}
        setShow={setShow6}
        selectedForm={selectedForm}
        uploadSuccessFunc={uploadSuccessFunc}
      />

      <AssignForm
        show={show7}
        setShow={setShow7}
        selectedForm={selectedForm}
        uploadSuccessFunc={uploadSuccessFunc}
        refreshPage={loadCourse}
      />

      {/*
     
     
     

      <AssignPlanner
        show={show6}
        setShow={setShow6}
        selectedPlanner={selectedPlanner}
        uploadSuccessFunc={uploadSuccessFunc}
        refreshPage={loadCourse}
      /> */}
    </>
  );
};

export default Forms;
